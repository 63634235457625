// Frontend Model
export class OrderArticleReservation {
	batchNumber: number;
	articleNumber: string;
	amountReserved: number;
}

// Backend Model
export class Reservation {
	batchNumber: number;
	articleNumber: string;
	customerOrderNumber: number;
	quantity: number;
}
