import {Component, Input} from '@angular/core';
import {MatTooltip, MatTooltipModule, TooltipPosition} from "@angular/material/tooltip";

@Component({
	selector: 'ali-tooltip',
	standalone: true,
	imports: [MatTooltipModule, MatTooltip],
	templateUrl: './tooltip.component.pug',
	styleUrl: './tooltip.component.sass'
})
export class TooltipComponent {
	@Input()
	tooltipContent!: string;
	@Input()
	tooltipPosition: TooltipPosition = 'above';
	@Input()
	tooltipType: string = 'info';

	constructor() {
	}
}
