import { Pipe, PipeTransform } from '@angular/core';
import { EnumItem } from '../models';
import { EnumUtils } from '../utils/enum/enum.utils';

@Pipe({name: 'aliEnumText', standalone: true})
export class EnumTextPipe implements PipeTransform {
	transform(enumID: number, enumList: EnumItem[]): string {
		if (enumID as any === '' || enumID === undefined || enumID === null || enumID === 0) {
			return '';
		}
		return EnumUtils.getNameForEnumId(enumID, enumList);
	}

}
