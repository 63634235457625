import {Component} from '@angular/core';

@Component({
	selector: 'ali-loader',
	templateUrl: './loader.component.pug',
	standalone: true,
	styleUrls: ['./loader.component.sass']
})
export class LoaderComponent {

}

