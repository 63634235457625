import {Component, OnInit} from '@angular/core';
import {NavigationItemModel} from "../../model/navigation-item/navigation-item.model";
import {SharedUserModel} from "@shared/models/shared-user.model";
import {KeycloakService} from "keycloak-angular";
import {ConfigService} from "@shared/services";
import {NavigationEnd, Router, RouterModule} from "@angular/router";
import {filter} from "rxjs/operators";
import {NgClass} from "@angular/common";
import {MatMenuModule} from "@angular/material/menu";

@Component({
	selector: 'ali-navigation',
	standalone: true,
	imports: [RouterModule, NgClass, MatMenuModule],
	templateUrl: './navigation.component.pug',
	styleUrl: './navigation.component.sass'
})
export class NavigationComponent implements OnInit {
	navItems: NavigationItemModel[];
	userProfile: SharedUserModel = new SharedUserModel();
	activeOptions: { exact: boolean } = {exact: false};
	activeSubOptions: { exact: boolean } = {exact: true};
	activeSubNav: { [key: string]: boolean } = {};

	satellite: boolean = false;
	toggled: boolean = false;
	isDarkMode: boolean = false;
	customer: string = 'Einhornkraut';

	constructor(
		private keycloakService: KeycloakService,
		private configService: ConfigService,
		private router: Router
	) {
	}

	ngOnInit(): void {
		this.satellite = this.configService.getConfig().satellite;
		this.customer = this.configService.getConfig().customer;
		this.initNavList();
		this.getKeyCloakUserDetails();
		this.initRouteListener();
		this.setInitialActiveSubNav();

		const savedToggledState = localStorage.getItem('navToggled');
		if (savedToggledState) {
			this.toggled = JSON.parse(savedToggledState);
		}

		const savedTheme = localStorage.getItem('cawawi-theme');
		this.isDarkMode = savedTheme === 'dark';
		this.configService.switchTheme(this.isDarkMode);
	}

	logout(): void {
		this.keycloakService.getKeycloakInstance().logout();
	}

	toggleNav(): void {
		this.toggled = !this.toggled;
		localStorage.setItem('navToggled', JSON.stringify(this.toggled));

	}

	toggleSubNav(navItemId: string): void {
		// Reset all other sub-navs to false
		for (const key in this.activeSubNav) {
			if (this.activeSubNav.hasOwnProperty(key)) {
				this.activeSubNav[key] = false;
			}
		}

		// Toggle the selected sub-nav
		this.activeSubNav[navItemId] = !this.activeSubNav[navItemId];
	}

	toggleTheme(): void {
		this.isDarkMode = !this.isDarkMode;
		this.configService.switchTheme(this.isDarkMode);
		localStorage.setItem('cawawi-theme', this.isDarkMode ? 'dark' : 'light');
	}

	private initRouteListener(): void {
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(() => {
				this.setInitialActiveSubNav();
			})
	}

	private getKeyCloakUserDetails(): void {
		// @ts-ignore
		this.userProfile = this.keycloakService.getKeycloakInstance().profile;
	}

	private setInitialActiveSubNav(): void {
		const currentRoute = this.router.url;
		for (const navItem of this.navItems) {
			if (navItem.subItems) {
				for (const subItem of navItem.subItems) {
					const fullPath = `${navItem.path}/${subItem.path}`;
					if (currentRoute.includes(subItem.path) && currentRoute === fullPath) {
						this.activeSubNav[navItem.id] = true;
					}
				}
			}
		}
	}

	private initNavList(): void {
		if (this.satellite) {
			this.navItems = [
				{
					path: '/article',
					title: 'Artikel',
					id: 'article',
					icon: 'fa-flask-vial',
					subItems: [
						{
							title: 'Übersicht',
							path: 'list',
							id: 'articleList'
						}
					]
				},
				{
					path: '/customer',
					title: 'Bestellungen',
					id: 'customer',
					icon: 'fa-users',
					subItems: [
						{
							title: 'Übersicht',
							path: 'order/list',
							id: 'customerOrderListall'
						},
						{
							title: 'Kunden',
							path: 'list',
							id: 'customerList'
						},
					]
				},
			];
		} else {
			this.navItems = [
				{
					path: '/article',
					title: 'Artikel',
					id: 'article',
					icon: 'fa-flask-vial',
					subItems: [
						{
							title: 'Übersicht',
							path: 'list',
							id: 'articleList'
						},
						{
							title: 'INCI',
							path: 'inci',
							id: 'inci'
						},
						{
							title: 'Eigenschaften',
							path: 'characteristic',
							id: 'characteristic'
						}
					]
				},
				{
					path: '/customer',
					title: 'Bestellungen',
					id: 'customer',
					icon: 'fa-users',
					subItems: [
						{
							title: 'Übersicht',
							path: 'order/list',
							id: 'customerOrderListall'
						},
						{
							path: 'reservations',
							title: 'Reservierungen',
							id: 'reservations'
						},
						{
							title: 'Kunden',
							path: 'list',
							id: 'customerList'
						},
					]
				},
				{
					path: '/material-order',
					title: 'Einkauf',
					id: 'material-order',
					icon: 'fa-shopping-cart',
					subItems: [
						{
							title: 'Einkaufsliste',
							path: 'purchase-list',
							id: 'purchase-list'
						}, {
							title: 'Offen',
							path: 'list/open',
							id: 'open-list'
						}, {
							title: 'Bestätigt',
							path: 'list/confirmed',
							id: 'confirmed-list'
						}, {
							title: 'Erhalten',
							path: 'list/received',
							id: 'received-list'
						}, {
							title: 'Abgeschlossen',
							path: 'list/completed',
							id: 'completed-list'
						},
						{
							title: 'Lieferanten',
							path: 'supplier/list',
							id: 'supplier-list'
						}
					]
				},
				{
					path: '/prescription/manager',
					title: 'Rezeptmanager',
					id: 'prescription',
					icon: 'fa-file-medical'
				},
				{
					path: '/production',
					title: 'Herstellung',
					id: 'production',
					icon: 'fa-scale-unbalanced-flip',
				}
			];
		}
	}

}
