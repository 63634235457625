import { BackendConfig } from './backend.config.model';
import { InfoMessageConfig } from './infoMessage.config.model';

export class Config {
	backend: BackendConfig;
	errorMessage: InfoMessageConfig;
	keycloak: any;
	shipping: any;
	satellite: boolean;
	themes: any;
	customer: string;
}
