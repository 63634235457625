import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
	selector: 'ali-prescription-detail-modal',
	standalone: true,
	imports: [],
	templateUrl: './prescription-detail-modal.component.pug',
	styleUrl: './prescription-detail-modal.component.sass'
})
export class PrescriptionDetailModalComponent implements OnInit {

	constructor(
		public dialogRef: MatDialogRef<PrescriptionDetailModalComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
	}

	ngOnInit(): void {
		console.log(this.data)
	}

	onClose(): void {
		this.dialogRef.close();
	}
}
