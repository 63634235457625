import {PagingModel} from "../../../app/model/unassigend-prescription/unassigned-prescription.model";
import {BalanceModel} from "./customer.order.details.model";

export class CustomerOrderListModel {
	orderNumber: string = '';
	orderDate: string = '';
	customer: CustomerListModel = new CustomerListModel({});
	paid: boolean;
	customerOrderStatus: number;
	shopwareOrderNumber: string = '';
	shippingType: string;
	wixOrderNumber: string;
	prescription: boolean;
	confirmed: boolean;
	producible: boolean;

	selected: boolean;
	balanceSummary: BalanceModel;

	constructor(data: any) {
		// tslint:disable-next-line:typedef
		Object.keys(data).forEach(key => this[key] = data[key]);

		this.customer = new CustomerListModel(this.customer);
	}
}

export class CustomerOrderPagingModel {
	paging: PagingModel;
	orders: CustomerOrderListModel[];
}

export class CustomerListModel {
	customerNumber: string = '';
	salutation: string = '';
	customerFirstName: string = '';
	customerLastName: string = '';

	constructor(data: any) {
		// tslint:disable-next-line:typedef
		Object.keys(data).forEach(key => this[key] = data[key]);
	}

	toString(): string {
		return this.customerFirstName + ' ' + this.customerLastName;
	}
}

