export class CustomerOrderQueryModel {
	page: number | null = null;
	per_page: number | null = null;
	filter: string | null = null;
	pharmacy: string | null = null;
	startDate: string | null = null;
	endDate: string | null = null;
	prescription: string | null = null;
	type: number | null = null;
	isPayed: boolean | null = null;
	typeIsNot: boolean | null = null;
}
