import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'aliInfomessageSeverity'})
export class InfomessageSeverityClassPipe implements PipeTransform {

	transform(severity: number): string {
		const defaultClass = 'alert ';

		switch (severity) {
			case 0:
				return defaultClass + 'bg-weed-info-message-success';
			case 1:
				return defaultClass + 'bg-weed-info-message-error';
			case 2:
				return defaultClass + 'bg-weed-info-message-warning';
			default:
				return defaultClass + 'bg-weed-info-message-danger';
		}
	}

}
