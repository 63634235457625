import {Injectable} from '@angular/core';
import {BaseJsonService} from "@shared/services/base.service";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {Response} from "../../model/response/response.model";
import {SatelliteConfigModel} from "../../model/satellite-config/satellite-config.model";

@Injectable({
	providedIn: 'root'
})
export class AdministrationService extends BaseJsonService {

	getSatelliteConfigurations(): Observable<Response<SatelliteConfigModel>> {
		const url = this.generateUrl('%s', 'satellite')
		return this.http.get(url, this.options).pipe(
			map((res: Response<SatelliteConfigModel>) => {
				return res;
			}),
			catchError(BaseJsonService.handleError)
		)
	}

	updateSatelliteConfigurations(config: SatelliteConfigModel): Observable<any> {
		const url = this.generateUrl('%s', 'satellite')
		return this.http.patch(url, config, this.options).pipe(
			map((res: Response<any>) => {
				return res;
			}),
			catchError(BaseJsonService.handleError)
		)
	}
}
