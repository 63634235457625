import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './components/not-found-component/not-found-component.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {AuthGuard} from './guards/auth.guard';
import {KeycloakService} from 'keycloak-angular';
import {PrescriptionComponent} from './components/prescription/prescription.component';
import {
	PrescriptionManagerCheckAssignmentComponent
} from "./components/prescription-manager-check-assignment/prescription-manager-check-assignment.component";
import {SatelliteGuard} from "./guards/satellite.guard";
import {unassignedPrescriptionsGuard} from "./guards/leave-prescription.guard";
import {AdministrationComponent} from "./components/administration/administration.component";


const routes: Routes = [
	{
		path: '',
		redirectTo: 'article',
		pathMatch: 'full'
	}, {
		path: 'home',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		title: 'Q-Weed - Dashboard'
	}, {
		path: 'article',
		canActivate: [AuthGuard],
		loadChildren: () => import('../modules/article/article.module').then(m => m.ArticleModule),
		title: 'Q-Weed - Artikelverwaltung'
	}, {
		path: 'inci',
		canActivate: [AuthGuard, SatelliteGuard],
		loadChildren: () => import('../modules/inci/inci.module').then(m => m.InciModule),
		title: 'Q-Weed - INCI-Verwaltung'
	}, {
		path: 'characteristic',
		canActivate: [AuthGuard, SatelliteGuard],
		loadChildren: () => import('../modules/characteristic-list/characteristic-list.module').then((m: any) => m.CharacteristicListModule),
		title: 'Q-Weed - Eigenschaftenverwaltung'
	}, {
		path: 'h&p',
		canActivate: [AuthGuard, SatelliteGuard],
		loadChildren: () => import('../modules/precaution-list/precaution-list.module').then(m => m.PrecautionListModule),
		title: 'Q-Weed - H&P Verwaltung'
	}, {
		path: 'webarticle',
		canActivate: [AuthGuard, SatelliteGuard],
		loadChildren: () => import('../modules/article-enrichment/article-enrichment.module').then(m => m.ArticleEnrichmentModule),
		title: 'Q-Weed - Webshop - Artikelverwaltung'
	}, {
		path: 'customer',
		canActivate: [AuthGuard],
		loadChildren: () => import('../modules/customer/customer.module').then(m => m.CustomerModule),
		title: 'Q-Weed - Kundenverwaltung'
	}, {
		path: 'customer-order',
		canActivate: [AuthGuard],
		loadChildren: () => import('../modules/customer-order/customer.order.module').then(m => m.CustomerOrderModule),
		title: 'Q-Weed - Kundenbestellungen'
	}, {
		path: 'reservations',
		canActivate: [AuthGuard, SatelliteGuard],
		loadChildren: () => import('../modules/reservations/reservations.module').then(m => m.ReservationsModule),
		title: 'Q-Weed - Reservierungen'
	}, {
		path: 'material-order',
		canActivate: [AuthGuard, SatelliteGuard],
		loadChildren: () => import('../modules/material-order/material.order.module').then(m => m.MaterialOrderModule),
		title: 'Q-Weed - Einkauf'
	}, {
		path: 'supplier',
		canActivate: [AuthGuard, SatelliteGuard],
		loadChildren: () => import('../modules/supplier/supplier.module').then(m => m.SupplierModule),
		title: 'Q-Weed - Lieferantenverwaltung'
	}, {
		path: 'production',
		canActivate: [AuthGuard, SatelliteGuard],
		loadChildren: () => import('../modules/production/production.module').then(m => m.ProductionModule),
		title: 'Q-Weed - Produktion'
	}, {
		path: 'prescription/manager',
		canActivate: [AuthGuard, SatelliteGuard],
		component: PrescriptionComponent,
		title: 'Q-Weed - Rezeptverwaltung'
	}, {
		path: 'prescription/manager/assignment-check',
		canActivate: [AuthGuard, SatelliteGuard],
		component: PrescriptionManagerCheckAssignmentComponent,
		title: 'Q-Weed - Rezeptverwaltung',
		canDeactivate: [unassignedPrescriptionsGuard]
	}, {
		path: 'prescription/manager/assignment-check/:id',
		canActivate: [AuthGuard, SatelliteGuard],
		component: PrescriptionManagerCheckAssignmentComponent,
		title: 'Q-Weed - Rezeptverwaltung',
		canDeactivate: [unassignedPrescriptionsGuard]
	},
	{
		path: 'administration',
		canActivate: [AuthGuard],
		component: AdministrationComponent,
		title: 'Q-Weed - Administration'
	},
	{
		path: '404',
		component: NotFoundComponent,
		title: 'Uups - 404 - Seite nicht gefunden'
	}, {
		path: '**',
		redirectTo: '404'
	},

];


@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	providers: [AuthGuard, KeycloakService, SatelliteGuard]
})
export class AppRoutingModule {
}
