export class UploadModel {
	fileUpload: FileUploadModel;
}

export class FileUploadModel {
	name: string;
	dateModified?: Date;
	size: number;
	type: string;
	data?: string;
}
