import {CanDeactivateFn, UrlTree} from "@angular/router";
import {Observable} from "rxjs";

export const unassignedPrescriptionsGuard: CanDeactivateFn<any> = (component: CanComponentDeactivate) => {
	return component.canDeactivate();
}

export interface CanComponentDeactivate {
	canDeactivate: () => CanDeactivateType;
}

export type CanDeactivateType = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;
