import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {Modal} from 'src/modules/shared/components/modal/model';
import {ModalService} from 'src/modules/shared/services/modal.service';
import * as $ from 'jquery';
import {FormHostDirective} from 'src/modules/shared/directives/formHost.directive';

@Component({
	selector: 'ali-modal',
	styleUrls: ['./modal.component.sass'],
	templateUrl: './modal.component.pug',
})
export class ModalComponent implements OnInit, OnDestroy {
	private modal: Modal;
	private subscription: Subscription = new Subscription();

	visible: boolean = false;
	visibleAnimate: boolean = false;

	closeButtonVisible: boolean = true;
	escapeCloseActive: boolean = true;

	@Input()
	cssClass: string = '';

	@ViewChild(FormHostDirective, {static: true})
	formHost: FormHostDirective;

	@ViewChild('modalContainer', {static: false})
	modalContainer: ElementRef;

	private timeouts: number[] = [];

	// Modal component is initialized in app.component and therefore existing through the whole lifecycle of the app
	constructor(private modalService: ModalService) {
	}

	ngOnInit(): void {
		this.modalService.setComponent(this);
		this.subscription = this.modalService.subject.subscribe(
			(modal: Modal) => {
				this.modal = modal;
				this.show();
			}
		);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
		this.timeouts.forEach((timeout: number) => {
			clearTimeout(timeout);
		});
	}

	@HostListener('document:keyup', ['$event'])
	keyup(event: KeyboardEvent): void {
		switch (event.key) {
			case 'Escape': {
				if (this.escapeCloseActive) {
					this.close(true);
				}
				break;
			}
			case 'Enter': {
				this.handleEnter();
				break;
			}
			case 'Tab': {
				this.handleTab(event);
				break;
			}
		}
	}

	// TODO: Material Tab-Trap probieren
	// Confine tab-targets withing the modal, otherwise tab selects background-elements or event browser-elements
	private handleTab(event: KeyboardEvent): void {
		if (!this.modalService.componentLoaded()) {
			return;
		}
		const modal = $(this.modalContainer.nativeElement);

		if (!this.isTargetInsideModal(event.target)) {
			event.preventDefault();
			this.focusFirstInput(modal);
		}
	}

	private focusFirstInput(modal: any): void {
		const nextInputSelector = `input[tabindex="1"], select[tabindex="1"]`;

		const nextInput = modal.find(nextInputSelector);
		if (nextInput.length === 0) {
			const inputList = modal.find('input, select');
			if (inputList.length > 0) {
				inputList.get(0).focus();
			}
		} else {
			nextInput.focus();
		}
	}

	private isTargetInsideModal(target: EventTarget): boolean {
		return this.modalContainer.nativeElement.contains(target);
	}

	@HostListener('window:popstate')
	browserBack(): void {
		this.close(true);
	}

	private handleEnter(): void {
		const focus = $(':focus');
		if (focus.attr('type') === 'submit' ||
			focus.prop('tagName') === 'TEXTAREA') {
			return;
		}
	}

	private show(): void {
		this.visible = true;
		this.setBodyClass();
		const timeout = window.setTimeout(() => this.visibleAnimate = true, 100);
		this.timeouts.push(timeout);
	}

	private setBodyClass(): void {
		const body = $('body');
		body.addClass('modal-open');
	}

	private removeBodyClass(): void {
		const body = $('body');
		body.removeClass('modal-open');
	}

	close(userClosedManually: boolean = false, returnData?: any): void {
		if (this.modal === undefined) {
			return;
		}

		this.modal.modalOnClose(userClosedManually, returnData);
		this.visibleAnimate = false;
		const timeout = window.setTimeout(() => {
			this.visible = false;
			this.modalService.destroyComponent();
			this.modal = undefined;
			this.removeBodyClass();
		}, 0);
		this.timeouts.push(timeout);
		this.closeButtonVisible = true;
		this.escapeCloseActive = true;
	}
}
