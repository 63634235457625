import {Component, OnInit} from '@angular/core';
import {ConfigService} from '@shared/services';

interface DashboardItem {
	headline: string;
	content: string;
	link: string;
	icon: string;
}

@Component({
	selector: 'ali-dashboard',
	templateUrl: './dashboard.component.pug',
	styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit {
	items: Array<DashboardItem>;
	isSatellite: boolean;

	constructor(
		private configService: ConfigService
	) {}

	ngOnInit(): void {
		this.isSatellite = this.configService.getConfig().satellite;
		this.items = this.getDashboardItems();
	}

	private getDashboardItems(): any {
		if (this.isSatellite) {
			return [
				{
					headline: 'Artikel',
					content: 'Artikel-Bereich',
					link: '/article/list',
					icon: 'fa fa-flask'
				}, {
					headline: 'Kunden',
					content: 'Kunden-Bereich',
					link: '/customer/list',
					icon: 'fa fa-users'
				}, {
					headline: 'Kundenbestellungen',
					content: 'Kundenbestellungen-Bereich',
					link: '/customer-order/list/open',
					icon: 'fa fa-cube'
				}
			];
		} else {
			return [
				{
					headline: 'Artikel',
					content: 'Artikel-Bereich',
					link: '/article/list',
					icon: 'fa fa-flask'
				}, {
					headline: 'INCIs',
					content: 'INCI-Bereich',
					link: '/inci/list',
					icon: 'fa fa-list-ol'
				}, {
					headline: 'Eigenschaften',
					content: 'Eigenschafts-Bereich',
					link: '/characteristic/list',
					icon: 'fa fa-th-list'
				},
				{
					headline: 'Kunden',
					content: 'Kunden-Bereich',
					link: '/customer/list',
					icon: 'fa fa-users'
				}, {
					headline: 'Kundenbestellungen',
					content: 'Kundenbestellungen-Bereich',
					link: '/customer-order/list/open',
					icon: 'fa fa-cube'
				}, {
					headline: 'Rezeptmanager',
					content: 'Reszeptmanager',
					link: '/prescription/manager',
					icon: 'fa fa-file-medical'
				}, {
					headline: 'Warenbestellungen',
					content: 'Warenbestellungen-Bereich',
					link: '/material-order/list',
					icon: 'fa fa-truck'
				}, {
					headline: 'Lieferanten',
					content: 'Lieferanten-Bereich',
					link: '/supplier/list',
					icon: 'fa fa-vcard'
				},
				{
					headline: 'Herstellung',
					content: 'Artikel-Herstellung',
					link: '/production/list',
					icon: 'fa fa-pencil'
				}
			];
		}
	}

}
