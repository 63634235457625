// Pipes
import { Pipe, PipeTransform } from '@angular/core';
import { TaxRates } from '../utils/constants/constants';

@Pipe({name: 'aliNetto'})
export class NettoPipe implements PipeTransform {

	transform(bruttoPrice: number, taxRateEnumId: number): number {
		if (taxRateEnumId === TaxRates.NINETEEN) {
			return (bruttoPrice / 119) * 100;
		}
		if (taxRateEnumId === TaxRates.SEVEN) {
			return (bruttoPrice / 107) * 100;
		}
		if (taxRateEnumId === TaxRates.SIXTEEN) {
			return (bruttoPrice / 116) * 100;
		}
		throw Error('Unkown Tax-Rate provided');
	}
}
