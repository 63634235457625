import { Observable, throwError as observableThrowError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Config } from 'src/modules/shared/models/config/config.model';
import { sprintf } from '../utils/string/string-format';

export interface HttpOptions {
	headers: HttpHeaders;
	params: HttpParams;
	body: {};
}

@Injectable({providedIn: 'root'})
export class BaseJsonService {
	protected serviceHostUrl: string;
	protected secondServiceHostUrl: string;
	protected productionServiceUrl: string;
	protected scaleUrl: string;

	protected options: HttpOptions = {
		headers: new HttpHeaders({
			'Content-Type':  'application/json'
		}),
		params: new HttpParams(),
		body: {}
	};

	config: Config;

	constructor(protected http: HttpClient,
				private configService: ConfigService) {
		this.config = this.configService.getConfig();
		this.serviceHostUrl = this.config.backend.baseURL;
		this.secondServiceHostUrl = this.config.backend.secondURL;
		this.productionServiceUrl = this.config.backend.productionURL;
		this.scaleUrl = this.config.backend.scaleUrl;
	}

	protected static handleError(error: HttpErrorResponse): Observable<never> {
		return observableThrowError(error || 'Server error');
	}

	protected generateUrl(format: string, ...params: any[]): string {
		const result = sprintf(format, ...params);
		if (result.error) {
			return '';
		}

		return this.serviceHostUrl + result.replaced;
	}

	protected generateUrlWithQueryParams(query: any, format: string, ...params: any[]): string {
		// Clean up query
		for (const [key, value] of Object.entries(query)) {
			if (value === null || value === 'null') {
				delete((query as any)[key]);
			}
		}

		const urlString = this.generateUrl(format, ...params)
		let origin;
		if (urlString.match(/^http/)) {
			origin = undefined;
		} else {
			origin = window.location.origin;
		}

		// @ts-ignore wrong representation in TS
		const urlparams = new URLSearchParams(query);
		const url = new URL(urlString, origin);
		// @ts-ignore wrong representation in TS
		url.search = urlparams;

		return url.toString()
	}

	protected generateSecondUrl(format: string, ...params: any[]): string {
		const result = sprintf(format, ...params);

		if (result.error) {
			return '';
		}
		return this.secondServiceHostUrl + result.replaced;
	}

	protected generateProductionUrl(format: string, ...params: any[]): string {
		const result = sprintf(format, ...params);

		if (result.error) {
			return '';
		}
		return this.productionServiceUrl + result.replaced;
	}
	protected generateScaleUrl(format: string, ...params: any[]): string {
		const result = sprintf(format, ...params);
		if (result.error) {
			return '';
		}

		return this.scaleUrl + result.replaced;
	}

}
