import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {InfomessageService} from "@shared/services";
import {AdministrationService} from "../../service/administration/administration.service";
import {HttpErrorResponse} from "@angular/common/http";
import {Response} from "../../model/response/response.model";
import {SatelliteConfigModel} from "../../model/satellite-config/satellite-config.model";
import {MatInputModule} from "@angular/material/input";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";

@Component({
	selector: 'ali-administration',
	standalone: true,
	imports: [FormsModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule],
	templateUrl: './administration.component.pug',
	styleUrl: './administration.component.sass'
})
export class AdministrationComponent implements OnInit, OnDestroy {
	private destroy$: Subject<void> = new Subject<void>();
	loading: false;
	vatRegex: RegExp = new RegExp(/^[A-Z]{2}\d{9}$/);
	satelliteConfigForm: FormGroup = new FormGroup({
		city: new FormControl(''),
		company: new FormControl(''),
		customerNumber: new FormControl(''),
		email: new FormControl(''),
		firstName: new FormControl(''),
		id: new FormControl(''),
		lastName: new FormControl(''),
		streetName: new FormControl(''),
		streetNumber: new FormControl(''),
		telephone: new FormControl(''),
		vatNumber: new FormControl('', Validators.pattern(this.vatRegex)),
		zipcode: new FormControl(''),
	})

	constructor(
		private infoMessageService: InfomessageService,
		private administrationService: AdministrationService
	) {
	}

	ngOnInit(): void {
		this.getSatelliteConfigurations();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private getSatelliteConfigurations(): void {
		this.administrationService.getSatelliteConfigurations()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (response: Response<SatelliteConfigModel>) => {
					this.satelliteConfigForm.patchValue(response.data);
				},
				error: (error: HttpErrorResponse) => {
					this.infoMessageService.createMessage(error.error.message, 'Error', 2);
				}
			})
	}

	save(): void {
		if (!this.satelliteConfigForm.valid) {
			this.satelliteConfigForm.markAllAsTouched();
			return;
		}
		this.updateSatelliteConfiguration();
	}

	private updateSatelliteConfiguration(): void {
		this.administrationService.updateSatelliteConfigurations(this.satelliteConfigForm.value)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (response: any) => {
					console.log('response', response);
				},
				error: (error: HttpErrorResponse) => {
					this.infoMessageService.createMessage(error.error.message, 'Error', 2);
				}
			})
	}
}
