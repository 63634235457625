<div class="infomessages" [class.active]="displayQueue.length &gt; 0"><div class="info-message" *ngFor="let message of displayQueue; let num = index;"><div class="wrapper alert flex flex-col" [class]="message.severity | aliInfomessageSeverity" role="alert"><strong>{{ '#'+(num+1) }} {{ message.date | date:'HH:mm' }} {{ message.title }}</strong><div class="text">{{ message.completeMessage }}</div><button class="close-button" type="button" aria-label="Close" (click)="close(message)"><i class="fa-solid fa-times"></i></button></div></div><div class="actions" *ngIf="displayQueue.length &gt; 1"><button type="button" mat-button (click)="closeAll()"><div class="flex gap-2"><div class="self-center">Alle Nachrichten schliessen</div><i class="fa-solid fa-times text-xl self-center"></i></div></button><!--.counter-text| Es werden {{ displayQueue.length }} von {{ allMessages.length + displayQueue.length }} Meldungen angezeigt

--></div></div><!--.infomessages([class.active]="displayQueue.length > 0").container(*ngIf="displayQueue.length > 0")
	.actions
		button.btn-tertiary(type="button", mat-button, (click)="closeAll()")
			| Alle Nachrichten schliessen
		span.counter-text
			| Es werden {{ displayQueue.length }} von {{ allMessages.length + displayQueue.length }} Meldungen angezeigt
	.row
		.col-12.error-msg(*ngFor="let message of displayQueue; let num = index;")
			div.wrapper.alert([class]="message.severity | aliInfomessageSeverity", role="alert")
				strong
					| {{ '#'+(num+1) }} {{ message.date | date:'HH:mm' }} {{ message.title }}

				| &nbsp;{{ message.completeMessage }}

				button(type="button", aria-label="Close", (click)="close(message)")
					i.fa-solid.fa-times-->