import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Infomessage } from 'src/modules/shared/models/infomessage/infomessage.model';
import { InfomessageService } from 'src/modules/shared/services/infomessage.service';
import { ConfigService } from 'src/modules/shared/services/config.service';
import { Config } from 'src/modules/shared/models/config/config.model';

@Component({
	selector: 'ali-infomessage',
	templateUrl: './infomessage.component.pug',
	styleUrls: ['./infomessage.component.sass'],
})
export class InfomessageComponent implements OnInit, OnDestroy {
	private subscription: Subscription = new Subscription();
	allMessages: Infomessage[] = [];
	displayQueue: Infomessage[] = [];

	maxCount: number = 8;
	timeToLive: number = 0;
	private config: Config;

	private currentTimeout: number;

	//
	constructor(private infomessageService: InfomessageService, private configService: ConfigService) {
		this.config = this.configService.getConfig();
	}

	ngOnInit(): void {
		this.subscription = this.infomessageService.subject.subscribe(
			(msg: Infomessage) => {
				this.addMessage(msg);
			}
		);
		this.maxCount = this.config.errorMessage.maxCount;
		this.timeToLive = this.config.errorMessage.timeToLive;
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();

		clearTimeout(this.currentTimeout);
	}

	closeAll(): void {
		this.displayQueue = [];
		this.allMessages = [];
		clearTimeout(this.currentTimeout);
	}

	close(msg: Infomessage): void {
		const index = this.displayQueue.indexOf(msg);
		if (index > -1) {
			this.displayQueue.splice(index, index + 1);
			// Nochmal die Q füllen
			this.showNextMessages();
		}
	}


	private addMessage(msg: Infomessage): void {
		if (typeof msg.completeMessage !== 'string') {
			msg.completeMessage = JSON.stringify(msg.completeMessage);
		}
		this.allMessages.push(msg);
		this.showNextMessages();
	}

	// Queue-Prinzip: Anzeige Queue auffüllen, bis kein Platz mehr ist
	// Wenn die Anzeige-Queue leer ist (wird nach der TTL geleert), schauen ob was neues reinkam
	private showNextMessages(): void {
		// Füll die AnzeigeQ auf, wenn nicht voll und daten da
		while (this.displayQueueHasSlot() && this.moreMessagesAvailable()) {
			this.addNextMessageToDisplayQueue();
			this.showDisplayQueueUntilTimeIsUp();
		}
	}

	private showDisplayQueueUntilTimeIsUp(): void {
		clearTimeout(this.currentTimeout);
		this.currentTimeout = window.setTimeout(() => {
			this.displayQueue = [];
			// Wenn fertig, schau rein, ob in der zwischenzeit was reinkam / noch etwas zu tun ist
			this.showNextMessages();
		}, this.timeToLive);
	}

	private addNextMessageToDisplayQueue(): void {
		const nextMessage = this.allMessages.splice(0, 1)[0];
		this.displayQueue.push(nextMessage);
	}

	private displayQueueHasSlot(): boolean {
		return this.displayQueue.length < this.maxCount;
	}

	private moreMessagesAvailable(): boolean {
		return this.allMessages.length > 0;
	}

}
