import { ComponentFactoryResolver, ComponentRef, Injectable, Type } from '@angular/core';
import { Subject } from 'rxjs';
import { Modal } from 'src/modules/shared/components/modal/model';
import { ModalComponent } from 'src/modules/shared/components/modal';
import { FormHostDirective } from '../directives';

@Injectable({
	providedIn: 'root'
})
export class ModalService {
	subject: Subject<Modal> = new Subject();
	private formHost: FormHostDirective;
	private component: ModalComponent;
	private componentRef: ComponentRef<any>;
	private resolver: ComponentFactoryResolver;

	constructor() {
	}

	componentLoaded(): boolean {
		return this.componentRef !== undefined && this.componentRef !== null;
	}

	// resolver needs to provided - every module has its own Resolver for their EntryComponents
	// a general resolver would mean, that SharedModul itself would need to know all those components
	// See: https://blog.angularindepth.com/here-is-what-you-need-to-know-about-dynamic-components-in-angular-ac1e96167f9e
	create(resolver: ComponentFactoryResolver, type: Type<Modal>, data: any, callback?: (cancelled: boolean, returnData: any) => void): any {
		if (this.formHost === undefined) {
			console.error('Modal', 'No View assigned');
			return null;
		}
		this.resolver = resolver;
		const component = this.loadComponent(type, data);
		this.handleModalCallback(component, callback);

		this.subject.next(component);
		return component;
	}

	/**
	 * Wrapper-Callback:
	 * Sowohl die erzeugende ParentComponent (Detailseite bspw.) als auch die Modal-Component selbst bekommen mit,
	 * ob der User das Modal manuell geschlossen hat
	 * Da nach "create" der Parent-Callback nicht mehr bekannt ist, wird der Component-Callback durch einen Wrapper ersetzt,
	 * der beides enthält (Component & Parentcallback)
	 *
	 * ReturnData:
	 * Beim Schließen des Modals (nach erfolgreichem Request bspw.) sollen optionale Rückgabewerte (Response bspw.) durchgereicht werden
	 * Die instanziierte Modal-Component ruft dafür "modalRef.close()" auf -> da müssen die Rückgabewerte rein
	 * modalRef.close ruft letztendlich nur modalOnClose auf und da müssen die Rückgabewerte durchgereicht werden
	 * Da modalOnClose durch den untenstehenden Wrapper ersetzt wurden, werden die Rückgabewerte dann an den ParentCallback weitergereicht
	 * Das ModalOnClose der Modal-Component benötigt diese nicht (die gibt sie ja letztendlich zurück)
	 * Gleiche Logik / gleicher Parameter-Workflow gilt für das "manuallyClosed"
	 */
	private handleModalCallback(component: Modal, parentComponentCallback: any): boolean {
		if (parentComponentCallback === undefined) {
			return;
		}

		const modalComponentCallback = component.modalOnClose;
		// WrapperCallback mit der gleichen Signatur wie das Interface
		const newModalComponentCallback = (manuallyClosed: boolean, returnData?: any): void => {
			modalComponentCallback.apply(component, [manuallyClosed]);
			parentComponentCallback(manuallyClosed, returnData);
		};

		component.modalOnClose = newModalComponentCallback;
	}

	// Called from ModalComponent on init -> ModalComponent is initialized in AppComponent (globally)
	setComponent(component: ModalComponent): void {
		this.component = component;
		this.formHost = this.component.formHost;
	}

	// Called from ModalComponent. Modal-Ref in ModalComponent is also cleared
	// Necessary to kill the inlined components, otherwise their close-callback is executed on every change in parent-components
	destroyComponent(): void {
		this.componentRef.destroy();
		this.componentRef = null;
	}

	private loadComponent(type: Type<Modal>, data: any): any {
		if (!data) {
			return;
		}

		this.component.cssClass = data.cssClass || 'fit-content';

		const componentFactory = this.resolver.resolveComponentFactory(type);

		const viewContainerRef = this.formHost.viewContainerRef;
		viewContainerRef.clear();

		const componentRef = viewContainerRef.createComponent(componentFactory);
		this.componentRef = componentRef;
		const instance = (componentRef.instance as Modal);
		instance.modalOnInit(this.component, data);
		return instance;
	}
}
