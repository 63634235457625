// src/app/guards/satellite.guard.ts
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {ConfigService} from "@shared/services";

@Injectable({
	providedIn: 'root'
})
export class SatelliteGuard implements CanActivate {
	constructor(private configService: ConfigService, private router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const config = this.configService.getConfig();
		if (config && config.satellite === false) {
			return true;
		} else {
			this.router.navigate(['/404']);
			return false;
		}
	}
}
