export interface ReplaceResult {
	replaced: string;
	error: boolean;
}

export function sprintf(format: string, ...params: any[]): ReplaceResult {
	let error = false;
	let i = 0;
	const replaced = format.replace(/%d|%s/g, (match: string): string => {
		if (params.length === i) {
			error = true;
			return '';
		}
		const value = params[i];
		i++;

		switch (match) {
			case '%d':
				return parseInt(value, 10).toString();
			case '%s':
				return value;
		}

		return '';
	});

	return {replaced, error};
}
