import {Injectable} from '@angular/core';
import {BaseJsonService} from 'src/modules/shared/services/base.service';
import {Observable, of} from 'rxjs';
import {EnumItem, EnumResponse} from '../models';
import {catchError, map} from 'rxjs/operators';


@Injectable({
	providedIn: 'root'
})
export class ConstantsService extends BaseJsonService {
	private cachedConstants: Map<string, EnumItem[]> = new Map<string, EnumItem[]>();

	getArticleTypesForSearch(): Observable<EnumItem[]> {
		return this.getEnumItems('constants/articleTypes').pipe(
			map((data: EnumItem[]) => {
				// Add Option "all" - backend does not know this ArticleType
				return [...data, {id: 999, name: 'Alle'} as EnumItem];
			})
		);
	}

	getExtractionStatus(): Observable<EnumItem[]> {
		return this.getEnumItems('constants/extractionStatus');
	}

	getArticleTypes(): Observable<EnumItem[]> {
		return this.getEnumItems('constants/articleTypes');
	}

	getStates(): Observable<EnumItem[]> {
		return this.getEnumItems('constants/states');
	}

	getCustomerOrderStatus(): Observable<EnumItem[]> {
		return this.getEnumItems('constants/customerOrderStatus');
	}

	getAllCustomerOrderStatus(): Observable<EnumItem[]> {
		return this.getEnumItems('constants/allCustomerOrderStatus');
	}

	getMaterialOrderStatus(): Observable<EnumItem[]> {
		return this.getEnumItems('constants/materialOrderStatus');
	}

	getUnits(): Observable<EnumItem[]> {
		return this.getEnumItems('constants/units');
	}

	getTaxRates(): Observable<EnumItem[]> {
		return this.getEnumItems('constants/taxRates');
	}

	getSalutations(): Observable<EnumItem[]> {
		return this.getEnumItems('constants/salutations');
	}

	getPaymentMethods(): Observable<EnumItem[]> {
		return this.getEnumItems('constants/paymentMethods');
	}

	getLabelTypes(): Observable<EnumItem[]> {
		return this.getEnumItems('constants/labelTypes');
	}

	getCountries(): Observable<EnumItem[]> {
		return this.getEnumItems('constants/countries', true);
	}

	private getEnumItems(urlPath: string, sorted: boolean = false): Observable<EnumItem[]> {
		const cachedResponse = this.getCachedResponse(urlPath, sorted);

		if (cachedResponse) {
			return of(cachedResponse);
		}

		return this.http.get(this.generateUrl('%s', urlPath), this.options).pipe(
			map((data: EnumResponse) => {
				this.cachedConstants.set(urlPath, data.items);
				return data.items;
			}),
			catchError(BaseJsonService.handleError)
		);
	}


	private getCachedResponse(url: string, sorted: boolean): EnumItem[] {
		const cached = this.cachedConstants.get(url);
		if (!cached) {
			return null;
		}

		if (!sorted) {
			return cached;
		}

		return cached.sort((a: EnumItem, b: EnumItem) => {
			return a.sortOrder - b.sortOrder;
		});
	}
}
