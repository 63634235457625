import { Pipe, PipeTransform } from '@angular/core';
import {EnumItem} from "@shared/models";
import {EnumUtils} from "@shared/utils/enum/enum.utils";

@Pipe({name: 'aliExtractionStatus', standalone: true})
export class ExtractionStatusPipe implements PipeTransform {
	transform(enumID: number, enumList: EnumItem[]): string {
		if (enumID as any === '' || enumID === undefined || enumID === null) {
			return '';
		}
		return EnumUtils.getNameForEnumId(enumID, enumList);
	}

}
