import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'matchingScoreColor',
  standalone: true
})
export class MatchingScoreColorPipe implements PipeTransform {

  transform(value: number): string {
	  if (value >= 1 && value <= 3) {
		  return 'score-red';
	  } else if (value >= 4 && value <= 6) {
		  return 'score-yellow';
	  } else if (value >= 7 && value <= 10) {
		  return 'score-green';
	  } else {
		  return 'black'; // default color if the score is out of range
	  }
  }

}
