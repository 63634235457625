import {switchMap, throwError as observableThrowError} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Config} from 'src/modules/shared/models/config/config.model';


@Injectable({
	providedIn: 'root'
})
export class ConfigService {
	protected options: any;
	private config: Config = new Config();

	constructor(protected http: HttpClient) {
		this.options = new HttpHeaders({'Content-Type': 'application/json'});
	}

	getConfig(): Config {
		return this.config;
	}

	// loadConfig(url: string = 'config/config.json'): Promise<any> {
	// 	return new Promise((resolve: any, reject: any): any => {
	// 		const request = this.http.get(url, this.options).pipe(
	// 			map((res: any) => {
	// 				return res;
	// 			}),
	// 			catchError((error: any) => {
	// 				reject(false);
	// 				return observableThrowError(error || 'Server error');
	// 			}));
	//
	// 		request.subscribe((data: Config) => {
	// 			this.config = data;
	// 			resolve(true);
	// 		}, (error: any) => {
	// 			console.error('Config Read Error', error);
	// 		});
	// 	});
	// }
	loadConfig(): Promise<any> {
		return new Promise((resolve: any, reject: any): any => {
			this.http.get('/cfg', this.options).pipe(
				switchMap((data: any) => {
					const configUrl = data.host + 'satellite/configuration';
					return this.http.get(configUrl, this.options).pipe(
						catchError((error: any) => {
							// if (error.status === 404) {
							// 	return this.http.get('config/config.json', this.options);
							// } else {
								reject(false);
								return observableThrowError(error || 'Server error');
							// }
						})
					);
				}),
				map((res: any) => {
					return res;
				}),
				catchError((error: any) => {
					if (error.status === 404) {
						return this.http.get('config/config.json', this.options);
					} else {
						reject(false);
						return observableThrowError(error || 'Server error');
					}
				})
			).subscribe((configData: Config) => {
				this.config = configData;
				resolve(true);
			}, (error: any) => {
				console.error('Config Read Error', error);
			});
		});
	}

	applyTheme(theme: any): void {
		for (const key in theme) {
			if (theme.hasOwnProperty(key)) {
				document.documentElement.style.setProperty(key, theme[key]);
			}
		}
	}

	switchTheme(isDarkMode: boolean): void {
		const selectedTheme = isDarkMode ? this.config.themes.dark : this.config.themes.light;
		this.applyTheme(selectedTheme);
	}
}
