import {Injectable, Query} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {BaseJsonService, HttpOptions} from 'src/modules/shared/services/base.service';
import {
	CustomerOrderDetailsModel,
	CustomerOrderListModel, CustomerOrderPagingModel,
	OrderArticleReservation,
	Reservation
} from 'src/modules/customer-order/models';
import {Article} from '../../article/model';
import {PaymentStatusModel} from '../models/payment-status.model';
import {BillLinkModel} from '../models/bill-link.model';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {query} from "@angular/animations";
import {QueryParamsModel} from "../models/query-params/query-params.model";
import {Response, ResponseList} from "../../../app/model/response/response.model";
import {CustomerOrderQueryModel} from "../../../app/model/customer-order-query/customer-order-query.model";

@Injectable({providedIn: 'root'})
export class CustomerOrderService extends BaseJsonService {

	getOrderList(type: string): Observable<CustomerOrderListModel[]> {
		const url = this.getListUrl(type);
		return this.http.get(url, this.options).pipe(
			map((data: CustomerOrderListModel[]) => {
				data.forEach((val: CustomerOrderListModel, index: number) => {
					const model = new CustomerOrderListModel(val);
					data[index] = model;
				})
				return data;
			}),
			catchError(BaseJsonService.handleError)
		);
	}

	getPaginatedAndFilteredOrderList(query: CustomerOrderQueryModel): Observable<Response<CustomerOrderPagingModel>> {
		const url = this.generateUrlWithQueryParams(query, '%s', 'order/paginated');
		return this.http.get(url, this.options).pipe(
			map((data: Response<CustomerOrderPagingModel>) => {
				return data;
			}),
			catchError(BaseJsonService.handleError)
		)
	}

	getPharmacyNames(): Observable<ResponseList<string>> {
		const url = this.generateUrl('%s', 'order/pharmacy');
		return this.http.get(url, this.options).pipe(
			map((data: ResponseList<string>) => {
				return data;
			}),
			catchError(BaseJsonService.handleError)
		)
	}

	getReserverationList(orderNumber: string): Observable<OrderArticleReservation[]> {
		const url = this.generateUrl('%s/%s', 'order/reservations', orderNumber);
		return this.http.get(url, this.options).pipe(
			map((data: Reservation[]) => {
				return data.map((res: Reservation) => {
					const model = new OrderArticleReservation();
					model.articleNumber = res.articleNumber;
					model.batchNumber = res.batchNumber;
					model.amountReserved = res.quantity;
					return model;
				});
			}),
			catchError(BaseJsonService.handleError)
		);

	}

	printCustomerBill(orderNumber: string): Observable<string> {
		const url = this.generateUrl('%s/%s', 'order/printCustomerOrderBill', orderNumber);
		return this.http.post(url, {}, this.options).pipe(
			map((data: string) => {
				return data;
			}),
			catchError(BaseJsonService.handleError)
		);
	}

	getPackageList(selected: CustomerOrderListModel[]): Observable<any> {
		const orderNumbers: string[] = []
		selected.forEach((item: CustomerOrderListModel) => {
			orderNumbers.push(item.orderNumber)
		});

		const url = this.generateUrlWithQueryParams({orderNumbers}, '%s', 'order/packagingListCsv');
		return this.http.get(url, {
			headers: new HttpHeaders({
				'Content-Type': 'text/csv'
			}),
			params: new HttpParams(),
			responseType: 'text'
		});
	}

	printPackagingList(orderNumber: string): Observable<void> {
		const url = this.generateUrl('%s/%s', 'order/printCustomerOrderPackagingList', orderNumber);
		return this.http.post(url, {}, this.options).pipe(
			map((data: any) => {
				return data;
			}),
			catchError(BaseJsonService.handleError)
		);
	}

	getOrderDetails(orderNumber: string): Observable<any> {
		const url = this.generateUrl('%s/%s', 'order/getOrderDetails', orderNumber);
		return this.http.get(url, this.options).pipe(
			map((data: any) => {
				return data;
			}),
			catchError(BaseJsonService.handleError)
		);
	}

	deleteOrder(orderNumber: string): Observable<{}> {
		const url = this.generateUrl('%s/%d', 'order/deleteOrder', orderNumber);
		return this.http.delete(url, this.options).pipe(
			catchError(BaseJsonService.handleError)
		);
	}

	createOrder(newOrder: CustomerOrderDetailsModel): Observable<CustomerOrderDetailsModel> {
		const url = this.generateUrl('%s', 'order/createOrder');
		return this.http.post(url, newOrder, this.options).pipe(
			map((res: CustomerOrderDetailsModel) => {
				return res;
			}),
			catchError(BaseJsonService.handleError)
		);
	}

	editOrder(newOrder: CustomerOrderDetailsModel): Observable<CustomerOrderDetailsModel> {
		const url = this.generateUrl('%s', 'order/editOrder');
		return this.http.post(url, newOrder, this.options).pipe(
			map((res: CustomerOrderDetailsModel) => {
				return res;
			}),
			catchError(BaseJsonService.handleError)
		);
	}

	setOpen(orderNumber: string): Observable<CustomerOrderDetailsModel> {
		const url = this.generateUrl('%s/%s', 'order/setCustomerOrderOpen', orderNumber);
		return this.http.get(url, this.options).pipe(
			map((res: CustomerOrderDetailsModel) => {
				return res;
			}),
			catchError(BaseJsonService.handleError)
		);
	}

	setPackageReady(orderNumber: string): Observable<CustomerOrderDetailsModel> {
		const url = this.generateUrl('%s/%s', 'order/setCustomerOrderReadyForPackaging', orderNumber);
		return this.http.get(url, this.options).pipe(
			map((res: CustomerOrderDetailsModel) => {
				return res;
			}),
			catchError(BaseJsonService.handleError)
		);
	}

	setShippingReady(orderNumber: string): Observable<CustomerOrderDetailsModel> {
		const url = this.generateUrl('%s/%s', 'order/setCustomerOrderReadyForShipping', orderNumber);
		return this.http.get(url, this.options).pipe(
			map((res: CustomerOrderDetailsModel) => {
				return res;
			}),
			catchError(BaseJsonService.handleError)
		);
	}

	setCompleted(orderNumber: string, batchList: any[]): Observable<CustomerOrderDetailsModel> {
		const url = this.generateUrl('%s/%s', 'order/setCustomerOrderShipped', orderNumber);
		return this.http.post(url, batchList, this.options).pipe(
			map((res: CustomerOrderDetailsModel) => {
				return res;
			}),
			catchError(BaseJsonService.handleError)
		);
	}

	setPaid(paymentStatusModel: PaymentStatusModel): Observable<any> {
		const url = this.generateUrl('%s/%s', 'order/setPaymentStatus', paymentStatusModel.orderNumber);
		return this.http.post(url, paymentStatusModel, this.options).pipe(
			map((res: any) => {
				return res;
			}),
			catchError(BaseJsonService.handleError)
		);
	}

	getArticleDetailsForOrderNumber(orderNumber: string): Observable<Article[]> {
		const url = this.generateUrl('%s/%s', 'order/customerOrderArticles', orderNumber);
		return this.http.get(url, this.options).pipe(
			map((res: Article[]) => {
				return res;
			}),
			catchError(BaseJsonService.handleError)
		);

	}

	renewReservation(orderNumber: string): Observable<any> {
		const url = this.generateUrl('%s/%s', 'order/reeval', orderNumber);
		return this.http.get(url, this.options).pipe(
			map((res: any) => {
				return res;
			}),
			catchError(BaseJsonService.handleError)
		);
	}

	writeOffBatches(batchList: any[]): Observable<void> {
		const url = this.generateUrl('%s', 'order/writeOff');
		return this.http.post(url, batchList, this.options).pipe(
			map(() => {
			}),
			catchError(BaseJsonService.handleError)
		);
	}

	private getListUrl(type: string): string {
		switch (type) {
			case 'all':
				return this.generateUrl('%s', 'order/getOrderList/all');
			// return this.generateUrl('%s', 'order/getAllOrders');
			case 'open':
				return this.generateUrl('%s', 'order/getOrderList/open');
			case 'paid':
				return this.generateUrl('%s', 'order/getOrderList/paid');
			case 'inproduction':
				return this.generateUrl('%s', 'order/getOrderList/inproduction');
			case 'packready':
				return this.generateUrl('%s', 'order/getOrderList/packready');
			case 'shipready':
				return this.generateUrl('%s', 'order/getOrderList/shipready');
			case 'completed':
				return this.generateUrl('%s', 'order/getOrderList/completed');
			case 'canceled':
				return this.generateUrl('%s', 'order/getOrderList/canceled');
			default:
				console.error('UNKNOWN TYPE: ', type);
			// return this.generateUrl('%s', 'order/getShippedCustomerOrders');
		}
	}

	createShipment(orderNumber: string): Observable<any> {
		const url = this.generateUrl('order/createShipmentByOrder/%s', orderNumber);
		return this.http.post(url, null, this.options).pipe(
			map((res: any) => {
				return res;
			}),
			catchError(BaseJsonService.handleError)
		);
	}

	createShipmentAuto(orderNumber: string, queryParams: QueryParamsModel): Observable<any> {
		const url = this.generateUrlWithQueryParams(queryParams, '%s/%s', 'order/createShipmentByOrder', orderNumber);
		return this.http.post(url.toString(), this.options).pipe(
			map((res: any) => {
				return res;
			}),
			catchError(BaseJsonService.handleError)
		);
	}

	printItemLabel(orderNumber: any, i: any): Observable<any> {
		const url = this.generateUrl('order/printItemLabel/%s/%d', orderNumber, i);
		return this.http.get(url, this.options).pipe(
			map((res: any) => {
				return res;
			}),
			catchError(BaseJsonService.handleError)
		);
	}
}





