import { Pipe, PipeTransform } from '@angular/core';

@Pipe({standalone: true, name: 'aliMailtoHref'})
export class MailtoHrefPipe implements PipeTransform {

	transform(email: string): string {
		if (!email) {
			return '';
		}
		return `mailto:${email}`;
	}

}
