import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Helpers
import {
	InputComponent,
	InputDatalistComponent,
	InputDatepickerComponent,
	SelectComponent,
	TextareaComponent
} from './components/form';
import {
	ConfirmDialogComponent,
	ConfirmDialogDirective
} from 'src/modules/shared/confirmDialog/confirmDialog.directive';
import { LoaderComponent } from 'src/modules/shared/components/loader/loader.component';
import { ModalComponent } from 'src/modules/shared/components/modal/modal.component';
import { FocusDirective, FormHostDirective } from './directives';
import {
	ArticleUnitListDisplayPipe,
	ArticleWebshopIconPipe,
	CurrencyPipe,
	CustomerNamePipe,
	EnumTextPipe,
	InfomessageSeverityClassPipe,
	MailtoHrefPipe,
	MaterialOrderStatusListIconPipe,
	NettoPipe,
	NewLineToBrPipe,
	SidebarLinkPipe
} from './pipes';
import { SupplierShowComponent } from './components/supplier-show';
import { RouterModule } from '@angular/router';
import { CustomerShowComponent } from './components/customer-show';
import { LabelPrintStatusComponent } from './components/label-print-status/label-print-status.component';
import { OrderStatusDisplayComponent } from './components/order-status-display';
import { OrderStatusUpdateButtonsComponent } from './components/order-status-update-buttons/order-status-update-buttons.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { ImageURLPipe } from './pipes/image.url.pipe';
import { InfomessageComponent } from './components/infomessage/infomessage.component';
import { InputErrorMessagesPipe } from './pipes/input-error-messages.pipe';
import { EnumFilterPipe } from './pipes/enum-filter.pipe';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {HazardgoodViewComponent} from '../hazardgood/components/view/hazardgood.view.component';
import {TextFieldModule} from '@angular/cdk/text-field';
import {MAT_DATE_LOCALE, NativeDateAdapter} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTableModule} from '@angular/material/table';
import {MatCommonModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatDialogModule} from '@angular/material/dialog';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input'
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatMenuModule} from '@angular/material/menu';
import {FileUploadComponent} from '@shared/components/file-upload/file-upload.component';
import {
	NotAllArticlesScannedModalComponent
} from "@shared/components/not-all-articles-scanned-modal/not-all-articles-scanned-modal.component";
import {MatStepper, MatStepperModule} from "@angular/material/stepper";
import {MatTabsModule} from "@angular/material/tabs";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TooltipComponent} from "../../app/components/tooltip/tooltip.component";

@NgModule({
	imports: [
		MatMomentDateModule,
		CommonModule,
		FormsModule,
		RouterModule,
		ReactiveFormsModule,
		DragDropModule,
		MatCommonModule,
		TextFieldModule,
		MatFormFieldModule,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		MatDialogModule,
		MatAutocompleteModule,
		MatInputModule,
		MatSelectModule,
		MatDatepickerModule,
		MatCheckboxModule,
		MatSlideToggleModule,
		MatMenuModule,
		MatStepperModule,
		ArticleUnitListDisplayPipe,
		MatTabsModule,
		LoaderComponent,
		FileUploadComponent,
		FocusDirective,
		MailtoHrefPipe,
		EnumTextPipe,
		ConfirmDialogDirective,
		MatTooltipModule,
		TooltipComponent
	],
	declarations: [
		SelectComponent,
		TextareaComponent,
		InputDatalistComponent,
		InputComponent,
		InputDatepickerComponent,
		ConfirmDialogComponent,
		MaterialOrderStatusListIconPipe,
		ArticleWebshopIconPipe,
		CurrencyPipe,
		SidebarLinkPipe,
		NettoPipe,
		CustomerNamePipe,
		FormHostDirective,
		ModalComponent,
		OrderStatusDisplayComponent,
		SupplierShowComponent,
		CustomerShowComponent,
		InfomessageSeverityClassPipe,
		NewLineToBrPipe,
		LabelPrintStatusComponent,
		OrderStatusUpdateButtonsComponent,
		BackButtonComponent,
		ImageURLPipe,
		InfomessageComponent,
		InputErrorMessagesPipe,
		EnumFilterPipe,
		HazardgoodViewComponent,

	],
	exports: [
		// --- Material
		MatCommonModule,
		MatFormFieldModule,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		MatDialogModule,
		MatAutocompleteModule,
		MatInputModule,
		MatSelectModule,
		MatDatepickerModule,
		MatCheckboxModule,
		MatSlideToggleModule,
		MatStepperModule,
		MatTabsModule,
		MatTooltipModule,
		// --- CDK
		DragDropModule,
		TextFieldModule,
		// --- Pipes
		EnumTextPipe,
		SidebarLinkPipe,
		MailtoHrefPipe,
		ArticleUnitListDisplayPipe,
		MaterialOrderStatusListIconPipe,
		ArticleWebshopIconPipe,
		NettoPipe,
		CurrencyPipe,
		CustomerNamePipe,
		InfomessageSeverityClassPipe,
		NewLineToBrPipe,
		ImageURLPipe,
		EnumFilterPipe,
		// --- Directives
		FormHostDirective,
		FocusDirective,
		ConfirmDialogDirective,
		// --- Components
		SelectComponent,
		InputComponent,
		TextareaComponent,
		InputDatalistComponent,
		InputDatepickerComponent,
		ConfirmDialogComponent,
		LoaderComponent,
		ModalComponent,
		SupplierShowComponent,
		CustomerShowComponent,
		LabelPrintStatusComponent,
		OrderStatusDisplayComponent,
		OrderStatusUpdateButtonsComponent,
		BackButtonComponent,
		InfomessageComponent,
		HazardgoodViewComponent,
		MatMenuModule,
		FileUploadComponent,
		TooltipComponent
	],
	providers: [
		{provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
		{provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
	]
})
export class SharedModule {

}
