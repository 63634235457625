// Updated PrescriptionUploadComponent
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject, takeUntil} from 'rxjs';
import {InfomessageService} from '@shared/services';
import {UploadModel} from "../../../modules/fileupload/model/uploadModel";
import {HttpErrorResponse} from "@angular/common/http";
import {PrescriptionService} from "../../service/prescription/prescription.service";

@Component({
	selector: 'ali-prescription-upload',
	templateUrl: './prescription-upload.component.pug',
	styleUrls: ['./prescription-upload.component.sass'],
	standalone: true
})
export class PrescriptionUploadComponent implements OnInit, OnDestroy, OnChanges {
	private destroy$: Subject<void> = new Subject<void>();

	@Input() acceptableFiles: string = '';
	@Input() multiple: boolean = false;
	@Input() autoUpload: boolean = false;
	@Output() emitListReload: EventEmitter<any> = new EventEmitter<any>();

	loading: boolean = false;
	dragging: boolean = false;
	filesInProgress: boolean = false;
	requestInProgress: boolean = false;
	uploadSuccess: boolean = false;
	progress: number = 0;
	files: UploadModel[] = [];
	maxSize: number = 10000000;
	private readers: FileReader[] = [];

	// New properties for queue management
	private fileQueue: UploadModel[] = [];
	private isUploading: boolean = false;

	constructor(
		private infoMessageService: InfomessageService,
		private prescriptionService: PrescriptionService
	) { }

	ngOnInit(): void { }

	ngOnChanges(): void { }

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	handleDragOver(event: Event): void {
		this.dragging = true;
		event.stopPropagation();
		event.preventDefault();
		(event as any).dataTransfer.dropEffect = 'copy';
	}

	fileDrop(event: Event): void {
		event.stopPropagation();
		event.preventDefault();
		this.dragging = false;
		this.filesInProgress = true;
		const files = (event as any).dataTransfer.files;
		this.handleFileList(files);
	}

	fileSelect(event: Event): void {
		const files = (event.target as HTMLInputElement).files;
		this.handleFileList(files);
	}

	private handleFileList(files: FileList | null): void {
		if (files != null) {
			if (files.length > 0) {
				for (let i = 0; i < files.length; i++) {
					if (files[i].size <= this.maxSize) {
						this.readAndSendFile(files[i], () => {
							if (!this.isUploading) {
								this.uploadNextFile();
							}
						});
					} else {
						this.infoMessageService.createMessage('Die Datei: ' + files[i].name + ' ist zu groß. Maximal ' + this.maxSize / 1000000 + ' MB');
					}
				}
			}
		}
	}

	private readAndSendFile(file: File, callback: () => void): void {
		const reader = new FileReader();
		const fileData = this.getFileData(file);
		reader.onload = (event: any) => {
			this.getSendHandler(fileData)(event as ProgressEvent);
			callback();
		}
		reader.readAsDataURL(file);
		this.uploadSuccess = false;
	}

	private getSendHandler(metadata: any): (data: ProgressEvent) => void {
		return (event: ProgressEvent): void => {
			metadata.fileUpload.data = (event.target as any).result.split(/,/)[1];
			this.files.push(metadata);
			this.fileQueue.push(metadata); // Add to queue
		};
	}

	private getFileData(file: File): UploadModel {
		const fileData: UploadModel = new UploadModel();
		fileData.fileUpload = {
			name: file.name,
			type: file.type,
			size: file.size,
			data: ''
		};
		return fileData;
	}

	private uploadNextFile(): void {
		if (this.fileQueue.length > 0) {
			this.isUploading = true;
			const nextFile = this.fileQueue.shift();
			if (nextFile) {
				this.uploadPrescription(nextFile);
			}
		} else {
			this.filesInProgress = false;
			this.isUploading = false;
			this.emitListReload.emit();
		}
	}

	private uploadPrescription(uploadModel: UploadModel): void {
		this.requestInProgress = true;
		this.prescriptionService.prescriptionUpload(uploadModel)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					this.requestInProgress = false;
					this.uploadSuccess = true;
					this.uploadNextFile(); // Upload the next file in the queue
				},
				error: (error: HttpErrorResponse) => {
					this.requestInProgress = false;
					this.infoMessageService.addServerMessage(error.error.message);
					this.uploadNextFile(); // Continue with the next file even if there is an error
				}
			})
	}
}
