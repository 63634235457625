import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainNavigationComponent } from './components/navigation/main-navigation.component';
import { SidebarNavigationComponent } from './components/sidebar/sidebar-navigation.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared';


@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		SharedModule
	],
	declarations: [
		MainNavigationComponent,
		SidebarNavigationComponent
	],
	exports: [
		MainNavigationComponent,
		SidebarNavigationComponent
	],
})
export class NavigationModule {
}
