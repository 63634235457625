import {Injectable} from "@angular/core";
import {BaseJsonService} from "@shared/services/base.service";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {Response} from "../../model/response/response.model";
import {OrderScanModel} from "../../model/order-scan/order-scan.model";

@Injectable({providedIn: 'root'})
export class OrderScanService extends BaseJsonService{

	getOrderByBatch(batchNumber: string): Observable<Response<OrderScanModel>> {
		const url = this.generateUrl('%s/%s', 'batch/getOrderNumber', batchNumber)
		return this.http.get(url, this.options).pipe(
			map((data: Response<OrderScanModel>) => {
				return data
			}),
			catchError(BaseJsonService.handleError)
		)
	}
}
