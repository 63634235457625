import {Injectable} from '@angular/core';
import {BaseJsonService} from "@shared/services/base.service";
import {PrescriptionListModel, PrescriptionModel} from "../../model/prescription/prescription.model";
import {Observable} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {Response, ResponseList} from "../../model/response/response.model";
import {PrescriptionQueryModel} from "../../model/prescription-query/prescription-query.model";
import {MatchingOrderModel} from "../../model/matching-order/matching-order.model";
import {AssignPrescriptionModel} from "../../model/assign-prescription/assign-prescription.model";
import {UploadModel} from "../../../modules/fileupload/model/uploadModel";
import {HttpHeaders} from "@angular/common/http";
import {SkipPrescriptionWorkModel} from "../../model/skip-prescription-model/skip-prescription-work.model";

@Injectable({
	providedIn: 'root'
})
export class PrescriptionService extends BaseJsonService {

	getUnassignedPrescriptions(query: PrescriptionQueryModel): Observable<Response<PrescriptionListModel>> {
		const url = this.generateUrlWithQueryParams(query, '%s', 'prescriptions/unassigned')
		return this.http.get(url, this.options).pipe(
			map((data: Response<PrescriptionListModel>) => {
				return data
			}),
			catchError(BaseJsonService.handleError)
		)
	}

	prescriptionUpload(file: UploadModel): Observable<any> {

		const url = this.generateUrl('prescriptions/unassigned');
		const formData: FormData = new FormData();

		// Append file data
		const blob = this.base64ToBlob(file.fileUpload.data, file.fileUpload.type);
		formData.append('prescriptionFile', blob, file.fileUpload.name);

		// HTTP headers
		const headers = new HttpHeaders({
			Accept: 'application/json'
		});

		return this.http.post(url, formData, {headers});
	}

	getPrescriptionMatchingOrders(prescriptionId: number): Observable<ResponseList<MatchingOrderModel>> {
		const url = this.generateUrl('%s/%s/%s', 'prescriptions/unassigned', prescriptionId, 'matchingOrders')
		return this.http.get(url, this.options).pipe(
			map((data: ResponseList<MatchingOrderModel>) => {
				return data
			}),
			catchError(BaseJsonService.handleError)
		)
	}

	updateUnassignedPrescription(prescription: PrescriptionModel): Observable<Response<PrescriptionModel>> {
		const url = this.generateUrl('%s', 'prescriptions/unassigned')
		return this.http.patch(url, prescription, this.options).pipe(
			map((data: Response<PrescriptionModel>) => {
				return data
			}),
			catchError(BaseJsonService.handleError)
		)
	}

	assignPrescriptionToOrder(assign: AssignPrescriptionModel): Observable<Response<any>> {
		const url = this.generateUrl('%s', 'prescriptions/unassigned/assign')
		return this.http.post(url, assign, this.options).pipe(
			map((data: Response<any>) => {
				return data
			}),
			catchError(BaseJsonService.handleError)
		)
	}

	getUnassignedPrescriptionForWork(): Observable<Response<PrescriptionModel>> {
		const url = this.generateUrl('%s', 'prescriptions/unassigned/work');
		return this.http.get(url, this.options).pipe(
			map((data: Response<PrescriptionModel>) => {
				return data
			}),
			catchError(BaseJsonService.handleError)
		)
	}

	getUnassignedPrescriptionForWorkWithId(id: number): Observable<Response<PrescriptionModel>> {
		const url = this.generateUrl('%s/%s', 'prescriptions/unassigned/work', id);
		return this.http.get(url, this.options).pipe(
			map((data: Response<PrescriptionModel>) => {
				return data
			}),
			catchError(BaseJsonService.handleError)
		)
	}

	skipUnassignedPrescriptionWork(skip: SkipPrescriptionWorkModel): Observable<any> {
		const url = this.generateUrl('%s', 'prescriptions/unassigned/work/skip');
		return this.http.post(url, skip, this.options).pipe(
			map((data: Response<any>) => {
				return data
			}),
			catchError(BaseJsonService.handleError)
		)
	}

	cancelUnassignedPrescriptionWork(): Observable<any> {
		const url = this.generateUrl('%s', 'prescriptions/unassigned/work/cancel');
		return this.http.delete(url, this.options).pipe(
			map((data: Response<any>) => {
				return data
			}),
			catchError(BaseJsonService.handleError)
		)
	}

	getPrescriptionById(id: number): Observable<Response<PrescriptionModel>> {
		const url = this.generateUrl('%s/%s', 'prescriptions/unassigned/work', id)
		return this.http.get(url, this.options).pipe(
			map((data: Response<PrescriptionModel>) => {
				return data
			}),
			catchError(BaseJsonService.handleError)
		)
	}

	deletePrescriptionById(id: number): Observable<Response<any>> {
		const url = this.generateUrl('%s/%s', 'prescriptions/unassigned', id)
		return this.http.delete(url, this.options).pipe(
			map((data: Response<any>) => {
				return data
			}),
			catchError(BaseJsonService.handleError)
		)
	}

	private base64ToBlob(base64: string, mime: string): Blob {
		const byteCharacters = atob(base64);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		return new Blob([byteArray], {type: mime});
	}
}
