import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Infomessage } from '../models/infomessage/infomessage.model';


@Injectable({
	providedIn: 'root'
})
export class InfomessageService {
	subject: Subject<Infomessage> = new Subject();

	constructor() {
	}

	addMessage(msg: Infomessage): void {
		this.subject.next(msg);
	}

	createMessage(completeMessage: string, title: string = '', severity: number = 0): void {
		const msg = new Infomessage();
		msg.title = title;
		msg.severity = severity;
		msg.completeMessage = completeMessage;
		msg.date = new Date();
		this.addMessage(msg);
	}

	addServerError(error?: any): void {
		if (error && error.completeMessage) {
			this.createMessage(error.completeMessage, 'Fehler', 3);
			return;
		}

		if (error && error.message) {
			this.createMessage(error.message, 'Fehler', 3);
			return;
		}

		this.createMessage(
			`Laden Sie bitte die Seite neu.
			Wenn es weiterhin nicht funktionieren sollte, wenden Sie sich bitte an einen Administrator.`,
			'Serverfehler:',
			3
		);
	}

	addServerMessage(msg: any): void {
		// let body: any;
		// try {
		// 	body = JSON.parse(msg._body);
		// } catch (e) {
		// 	this.addServerError(msg.error);
		// 	return;
		// }
		if (msg.error === undefined) {
			this.addServerError();
		} else {
			this.createMessage(msg.error, msg.status, 3);
		}
	}
}
