import { MatPaginatorIntl } from '@angular/material/paginator';
import {Injectable} from "@angular/core";
@Injectable({providedIn: 'root'})
export class CustomPaginatorIntl extends MatPaginatorIntl {
	itemsPerPageLabel: string = 'Elemente pro Seite:';
	nextPageLabel: string = 'Nächste Seite';
	previousPageLabel: string = 'Vorherige Seite';
	firstPageLabel: string = 'Erste Seite';
	lastPageLabel: string = 'Letzte Seite';

	getRangeLabel = (page: number, pageSize: number, length: number) => {
		if (length === 0 || pageSize === 0) {
			return `0 von ${length}`;
		}
		const startIndex = page * pageSize;
		const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
		return `${startIndex + 1} - ${endIndex} von ${length}`;
	}
}
