
export enum TaxRates {
	NINETEEN = 1,
	SEVEN,
	SIXTEEN
}

export const TaxRateNumberValues = new Map<number, number>([
	[TaxRates.NINETEEN, 19],
	[TaxRates.SEVEN, 7],
	[TaxRates.SIXTEEN, 16]
]);

export enum ArticleUnits {
	ml = 1,
	g
}

export enum ArticleTypes {
	BINDING = 1,
	EQUIPMENT,
	PACKAGE,
	BULKWARE,
	ALL = 999
}

// TODO was number but can be string?
export function GetArticleTypeAllReplaced(): any[] {
	return Object.values(ArticleTypes).filter((entry: string | number) => {
		if (typeof entry === 'number' && entry !== ArticleTypes.ALL) {
			return entry;
		}
	});
}


export enum LabelTypes {
	SMALL = 1,
	MIDDLE,
	BIG
}

export enum PaymentMethods {
	PAYPAL = 1,
	PREPAYMENT
}

export enum CustomerOrderStatus {
	OPEN = 1,
	IN_PRODUCTION,
	READY_FOR_PACKAGING,
	READY_FOR_SHIPPING,
	SHIPPED,
}

export enum MaterialOrderStatus {
	OPEN = 1,
	CONFIRMED,
	BOOKED,
	COMPLETED
}
