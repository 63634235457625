import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {InfomessageService} from '@shared/services';
import { NavigationEnd, Router} from '@angular/router';
import {OrderScanService} from "./service/order-scan-service/order-scan.service";
import {Subject, takeUntil} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {Response} from "./model/response/response.model";
import {OrderScanModel} from "./model/order-scan/order-scan.model";
import {filter} from "rxjs/operators";

@Component({
	selector: 'ali-root',
	templateUrl: './app.component.pug',
	styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, OnDestroy {
	private destroy$: Subject<void> = new Subject<void>();

	// Global barcode function variables
	startTime: number;
	endTime: number;
	barcode: string;
	prefix: string = 'CaWaWi#';
	secondPrefix: string = '#';
	activeRoute: string = '';

	constructor(
				private router: Router,
				private orderScanService: OrderScanService,
				private infomessageService: InfomessageService,
	) {
	}

	ngOnInit(): void {
		// this.getOrderByBatchNumber('240600050');
		this.initRouteListener();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	@HostListener('document:keydown', ['$event'])
	onKeyPress(event: KeyboardEvent): void {
		if (event.key !== 'Shift' && event.key !== 'Enter') {
			this.startTime = new Date().getTime();
			this.barcode += event.key;
		}
		if (event.key === 'Enter') {
			event.preventDefault();
			this.endTime = new Date().getTime();
			if (this.barcode.startsWith(this.prefix) && (this.endTime - this.startTime < 100)) {
				const scannedValue = this.barcode.substring(this.prefix.length);
				this.naviageToOrder(scannedValue);
				this.barcode = '';
			} else if (this.barcode.startsWith(this.secondPrefix) && (this.endTime - this.startTime < 100)) {
				const scannedValue = this.barcode.substring(this.secondPrefix.length);
				this.getOrderByBatchNumber(scannedValue);
				this.barcode = '';
			} else {
				this.barcode = '';
			}
		}
	}

	naviageToOrder(orderNumber: string): void {
		this.router.navigate(['/customer-order/details', orderNumber]);
	}

	private getOrderByBatchNumber(batchNumber: string): void {
		this.orderScanService.getOrderByBatch(batchNumber)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (data: Response<OrderScanModel>) => {
					if (data.success) {
						this.naviageToOrder(data.data.orderNumber);
					}
				},
				error: (error: HttpErrorResponse) => {
					this.infomessageService.createMessage(error.error.message, 'error', 3);
				}
			})
	}

	private initRouteListener(): void {
		this.router.events.pipe(
			filter((routerEvent: any): routerEvent is NavigationEnd => routerEvent instanceof NavigationEnd),
		).subscribe((event: NavigationEnd) => {
			const route: string = event.urlAfterRedirects;
			const splitRoute: string[] = route.split('/', 2);
			this.activeRoute = splitRoute[1];
		})
	}
}
