import {Injectable} from '@angular/core';
import {KeycloakInstance} from 'keycloak-js';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {ActivatedRouteSnapshot, CanLoad, Data, Route, Router, RouterStateSnapshot, UrlSegment} from '@angular/router';
import {ConfigService} from '@shared/services';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard implements CanLoad {

	private keycloak: KeycloakInstance;

	constructor(protected router: Router, protected keycloakAngular: KeycloakService, protected config: ConfigService) {
		super(router, keycloakAngular);
		this.keycloak = keycloakAngular.getKeycloakInstance();
	}

	isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		return this.checkAccessAllowed(route.data, 'home', this.roles);
	}

	canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
		// tslint:disable-next-line:typedef
		return new Promise(async (resolve, reject) => {
			try {
				this.authenticated = await this.keycloakAngular.isLoggedIn();
				this.roles = this.keycloakAngular.getUserRoles(true);

				// Von Rocco übernommen:
				const path = segments.map((segment: UrlSegment) => {
					return segment.path;
				}).join('/');

				// @ts-ignore
				const result = await this.checkAccessAllowed(route.data, path, this.roles);

				resolve(result);
			} catch (error) {
				reject('An error happened during access validation. Details:' + error);
			}

		});
	}

	checkAccessAllowed(data: Data, path: string, roles: string[]): Promise<boolean> {
		// tslint:disable-next-line:typedef
		return new Promise(async (resolve, reject) => {
			if (!this.authenticated) {
				this.keycloakAngular.login({
					redirectUri:  this.config.getConfig().keycloak.returnUrl + path
				});
			}

			let requiredRoles = data.roles as string[];

			if (!requiredRoles) {
				requiredRoles = [];
			}

			if (!requiredRoles || requiredRoles.length === 0) {
				return resolve(true);
			} else {
				if (!this.roles || this.roles.length === 0) {
					resolve(false);
				}
				let granted = false;
				for (const requiredRole of requiredRoles) {
					if (this.roles.indexOf(requiredRole) > -1) {
						granted = true;
						break;
					}
				}
				if (!granted) {
					alert("NOT LOGGED IN")
					this.router.navigateByUrl('/no-permission');
					reject(false);
				}
				resolve(granted);
			}
		});
	}
}
