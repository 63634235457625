import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LoaderComponent} from '@shared/components/loader/loader.component';
import {Router} from '@angular/router';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {DatePipe} from '@angular/common';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {
	PagingListModel,
	PrescriptionListModel,
	PrescriptionModel
} from '../../model/prescription/prescription.model';
import {PrescriptionQueryModel} from "../../model/prescription-query/prescription-query.model";
import {Subject, takeUntil} from "rxjs";
import {PrescriptionService} from "../../service/prescription/prescription.service";
import {HttpErrorResponse} from "@angular/common/http";
import {Response, ResponseList} from "../../model/response/response.model";
import {Sort} from "@angular/material/sort";
import {PrescriptionUploadComponent} from "../prescriptions-upload/prescription-upload.component";
import {ConstantsService, InfomessageService} from "@shared/services";
import {MatchingOrderModel} from "../../model/matching-order/matching-order.model";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {EnumItem} from "@shared/models";
import {ExtractionStatusPipe} from "../../pipe/extraction-status/extraction-status.pipe";
import {ConfirmDialogDirective} from "@shared/confirmDialog/confirmDialog.directive";

@Component({
	selector: 'ali-prescription',
	standalone: true,
	imports: [
		LoaderComponent,
		PrescriptionUploadComponent,
		MatPaginatorModule,
		DatePipe,
		MatTableModule,
		MatCheckboxModule,
		FormsModule,
		ExtractionStatusPipe,
		ReactiveFormsModule,
		ConfirmDialogDirective
	],
	templateUrl: './prescription.component.pug',
	styleUrl: './prescription.component.sass'
})
export class PrescriptionComponent implements OnInit, OnDestroy {
	private destroy$: Subject<void> = new Subject<void>();
	loading: boolean = false;
	dataSource: MatTableDataSource<PrescriptionModel> = new MatTableDataSource<PrescriptionModel>()
	paging: PagingListModel;
	displayedColumns: string[] = ['id', 'filename', 'createdAt', 'customer', 'assigned', 'status', 'skippedUntil', 'control']
	@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
	acceptableFiles: string[] = ['.pdf', '.jpg', '.jpeg', '.png'];
	assigned: string = 'all';

	prescriptionQuery: PrescriptionQueryModel = new PrescriptionQueryModel();

	extractionTypes$: EnumItem[];
	assignedFilterForm: FormGroup = new FormGroup({
		assigned: new FormControl('all')
	});

	constructor(
		private router: Router,
		private prescriptionService: PrescriptionService,
		private infoMessageService: InfomessageService,
		private constantService: ConstantsService
	) {
	}

	ngOnInit(): void {
		this.getExtractionStatus();
		this.handleSessionStorage();
		this.prescriptionQuery.assigned = 'unassigned';
		this.assignedFilterForm.get('assigned').patchValue('unassigned');
		this.getUnassignedPrescriptions(this.prescriptionQuery, true);
	}

	ngOnDestroy(): void {
	}

	setFilter(): void {
		this.prescriptionQuery.assigned = this.assignedFilterForm.get('assigned').value;
		sessionStorage.setItem('prescriptionQuery', JSON.stringify(this.prescriptionQuery));
		this.getUnassignedPrescriptions(this.prescriptionQuery);
	}

	openPrescriptionManager(id?: number): void {

		if (id) {
			this.router.navigate(['prescription/manager/assignment-check', id])
		} else {
			this.router.navigate(['prescription/manager/assignment-check'])
		}
	}

	sortData(sort: Sort): void {
		if (!sort.active || sort.direction === '') {
			return;
		}
		// this.dataSource.data = sortArticle(this.dataSource.data, sort.active, sort.direction);
	}

	reloadList(): void {
		this.prescriptionQuery.page = 1;
		this.prescriptionQuery.per_page = 20;
		this.prescriptionQuery.assigned = this.assigned;
		this.getUnassignedPrescriptions(this.prescriptionQuery);
	}

	deletePrescription(id: number): void {
		this.deletePrescriptionById(id);
	}

	changeEvent(event: any): void {
		if (event.pageIndex === this.paging.totalPages - 1) {
			this.prescriptionQuery.page = this.paging.totalPages;
			this.prescriptionQuery.per_page = event.pageSize;
			sessionStorage.setItem('prescriptionQuery', JSON.stringify(this.prescriptionQuery));
			this.getUnassignedPrescriptions(this.prescriptionQuery);
			return;
		}
		if (event.pageIndex === 0) {
			this.prescriptionQuery.page = 1;
			this.prescriptionQuery.per_page = event.pageSize;
			sessionStorage.setItem('prescriptionQuery', JSON.stringify(this.prescriptionQuery));
			this.getUnassignedPrescriptions(this.prescriptionQuery);
			return;
		}
		if (event.previousPageIndex > event.pageIndex) {
			this.prescriptionQuery.page = this.paging.pageNumber - 1;
			this.prescriptionQuery.per_page = event.pageSize;
			sessionStorage.setItem('prescriptionQuery', JSON.stringify(this.prescriptionQuery));
			this.getUnassignedPrescriptions(this.prescriptionQuery);
		}
		if (event.previousPageIndex < event.pageIndex) {
			this.prescriptionQuery.page = this.paging.pageNumber + 1;
			this.prescriptionQuery.per_page = event.pageSize;
			sessionStorage.setItem('prescriptionQuery', JSON.stringify(this.prescriptionQuery));
			this.getUnassignedPrescriptions(this.prescriptionQuery);
		}
		if (event.pageSize !== this.paging.pageSize) {
			this.prescriptionQuery.page = event.pageIndex + 1;
			this.prescriptionQuery.per_page = event.pageSize;
			sessionStorage.setItem('prescriptionQuery', JSON.stringify(this.prescriptionQuery));
			this.getUnassignedPrescriptions(this.prescriptionQuery);
		}
	}

	private getUnassignedPrescriptions(prescriptionQuery: PrescriptionQueryModel, init: boolean = false): void {
		this.prescriptionService.getUnassignedPrescriptions(prescriptionQuery)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (data: Response<PrescriptionListModel>) => {
					if (init) {
						this.dataSource.paginator = this.paginator;
					}
					this.paging = data.data.paging;
					this.dataSource.data = data.data.prescriptions;
				},
				error: (error: HttpErrorResponse) => {
					this.infoMessageService.addServerMessage(error.error.message);
				}
			})
	}

	private getMatchingOrders(id: number): void {
		this.prescriptionService.getPrescriptionMatchingOrders(id)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (data: ResponseList<MatchingOrderModel>) => {
					console.log('data', data);
				},
				error: (error: HttpErrorResponse) => {
					this.infoMessageService.addServerMessage(error.error.message);
				}
			})
	}

	private deletePrescriptionById(id: number): void {
		this.prescriptionService.deletePrescriptionById(id)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					this.reloadList();
				},
				error: (error: HttpErrorResponse) => {
					this.infoMessageService.createMessage(error.error.message, 'Error', 2);
				}
			})
	}

	private getExtractionStatus(): void {
		this.constantService.getExtractionStatus()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (status: EnumItem[]) => {
					this.extractionTypes$ = status;
				},
				error: (error: HttpErrorResponse) => {
					console.log('error', error);
				}
			})
	}

	private handleSessionStorage(): void {
		if (sessionStorage.getItem('prescriptionQuery')) {
			this.prescriptionQuery = JSON.parse(sessionStorage.getItem('prescriptionQuery'));
			this.assignedFilterForm.get('assigned').patchValue(this.prescriptionQuery.assigned)
		} else {
			this.prescriptionQuery.page = 1;
			this.prescriptionQuery.per_page = 20;
			this.prescriptionQuery.assigned = this.assignedFilterForm.get('assigned').value;
			sessionStorage.setItem('prescriptionQuery', JSON.stringify(this.prescriptionQuery));
		}
	}
}
