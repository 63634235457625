import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigService } from '../modules/shared/services';
import { NotFoundComponent } from './components/not-found-component/not-found-component.component';
import { SharedModule } from '../modules/shared';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { NavigationModule } from '../modules/navigation/navigation.module';
import { MatNativeDateModule } from '@angular/material/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {CustomPaginatorIntl} from "./mat-custom-settings/paginator/custom-paginator-intl";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {NavigationComponent} from "./components/navigation/navigation.component";

// KEEP IN!
registerLocaleData(localeDe);

@NgModule({
	imports: [
		BrowserModule,
		AppRoutingModule,
		ReactiveFormsModule,
		FormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		SharedModule,
		NavigationModule,
		MatNativeDateModule,
		KeycloakAngularModule,
		NavigationComponent
	],
	declarations: [
		AppComponent,
		NotFoundComponent,
		DashboardComponent,
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'de-DE' },
		{
			provide: APP_INITIALIZER,
			useFactory: serviceDependencyFactory,
			deps: [ConfigService, KeycloakService],
			multi: true
		},
		{ provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
  provideAnimationsAsync(),
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}

export function serviceDependencyFactory(config: ConfigService, keycloak: KeycloakService): () => Promise<any> {
	return (): Promise<any> => {
		return new Promise((resolve: any, reject: any) => {
			config.loadConfig().then((configSuccess: boolean) => {
				if (!configSuccess) {
					reject(configSuccess);
				}
				const selectedTheme = config.getConfig().themes.light;
				config.applyTheme(selectedTheme);

				return keycloak.init({
					config: config.getConfig().keycloak.config,
					loadUserProfileAtStartUp: true,
					initOptions: {
						onLoad: 'login-required',
					},
					bearerExcludedUrls: ['/assets']
				});
			}).then((keycloakSuccess: boolean) => {
				resolve(keycloakSuccess);
			})
				.catch(() => {
					reject();
				});
		});
	};
}

