import { Pipe, PipeTransform } from '@angular/core';
import { EnumItem } from '../models';
import { MaterialOrderStatus } from '../utils/constants/constants';
import { EnumUtils } from '../utils/enum/enum.utils';

@Pipe({name: 'aliMaterialOrderStatusListIcon'})
export class MaterialOrderStatusListIconPipe implements PipeTransform {

	transform(orderStatus: number, enumList: EnumItem[]): string {
		if (!orderStatus || orderStatus === MaterialOrderStatus.COMPLETED) {
			return '-';
		}

		const statusClass: string = this.getStatusClass(orderStatus);
		const statusText: string = this.getStatusText(orderStatus);
		const statusTitle: string = EnumUtils.getNameForEnumId(orderStatus, enumList);

		return `<span title="${statusTitle}" class="icon ${statusClass}">${statusText}</span>`;
	}

	private getStatusClass(orderStatus: number): string {
		switch (orderStatus) {
			case MaterialOrderStatus.OPEN:
				return 'open';
			case MaterialOrderStatus.CONFIRMED:
				return 'confirmed';
			case MaterialOrderStatus.BOOKED:
				return 'booked';
		}
	}

	private getStatusText(orderStatus: number): string {
		switch (orderStatus) {
			case MaterialOrderStatus.OPEN:
				return 'b';
			case MaterialOrderStatus.CONFIRMED:
				return 'B';
			case MaterialOrderStatus.BOOKED:
				return 'v';
		}
	}
}
