import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {FormsModule} from '@angular/forms';

@Component({
	selector: 'ali-prescription-manager-leave-modal',
	standalone: true,
	imports: [MatCheckboxModule, MatRadioModule, FormsModule],
	templateUrl: './leave-prescription-manager.modal.pug',
})
export class LeavePrescriptionManagerModal {

	constructor(
		public dialogRef: MatDialogRef<LeavePrescriptionManagerModal>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
	}

	close(): void {
		this.dialogRef.close(false);
	}

	confirm(): void {
		this.dialogRef.close(true);
	}
}
