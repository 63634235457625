import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PrescriptionStatusDisplayComponent} from '../prescription-status-display/prescription-status-display.component';
import {EnumItem} from '@shared/models';
import {MatRadioModule} from '@angular/material/radio';
import {MatFormFieldModule} from "@angular/material/form-field";
import {PrescriptionService} from "../../service/prescription/prescription.service";
import {Subject, takeUntil} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {Response, ResponseList} from "../../model/response/response.model";
import {PagingListModel, PrescriptionModel} from "../../model/prescription/prescription.model";
import {ConstantsService, InfomessageService} from "@shared/services";
import {MatchingOrderModel} from "../../model/matching-order/matching-order.model";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatchingScoreColorPipe} from "../../pipe/matching-score-color/matching-score-color.pipe";
import {DatePipe, NgClass} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {PrescriptionDetailModalComponent} from "../prescription-detail-modal/prescription-detail-modal.component";
import {CustomerOrderService} from "../../../modules/customer-order/service/customer.order.service";
import {
	CustomerOrderDetailsModel,
	CustomerOrderListModel,
	CustomerOrderPagingModel
} from "../../../modules/customer-order/models";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {SkipPrescriptionWorkModel} from "../../model/skip-prescription-model/skip-prescription-work.model";
import {AssignPrescriptionModel} from "../../model/assign-prescription/assign-prescription.model";
import {LoaderComponent} from "@shared/components/loader/loader.component";
import {CustomerOrderQueryModel} from "../../model/customer-order-query/customer-order-query.model";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {CanDeactivateType} from "../../guards/leave-prescription.guard";
import {LeavePrescriptionManagerModal} from "../leave-prescription-manager-modal/leave-prescription-manager.modal";
import {map} from "rxjs/operators";
import {EnumTextPipe} from "@shared/pipes";
import {ConfirmDialogDirective} from "@shared/confirmDialog/confirmDialog.directive";
import {MatCheckboxModule} from "@angular/material/checkbox";

@Component({
	selector: 'ali-prescription-manager-check-assignment',
	standalone: true,
	imports: [
		PrescriptionStatusDisplayComponent,
		MatRadioModule,
		MatFormFieldModule,
		FormsModule,
		MatchingScoreColorPipe,
		NgClass,
		LoaderComponent,
		MatTableModule,
		MatPaginatorModule,
		DatePipe,
		EnumTextPipe,
		ReactiveFormsModule,
		ConfirmDialogDirective,
		MatCheckboxModule
	],
	templateUrl: './prescription-manager-check-assignment.component.pug',
	styleUrl: './prescription-manager-check-assignment.component.sass'
})
export class PrescriptionManagerCheckAssignmentComponent implements OnInit, OnDestroy {
	@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
	private destroy$: Subject<void> = new Subject<void>();
	statusList: EnumItem[] = [];

	matchingOrders: MatchingOrderModel[] = [];
	selectedOrder: MatchingOrderModel = {orderNumber: 0, matchScore: 0};
	selectedOrderDetails: CustomerOrderDetailsModel;

	loadedPrescription: PrescriptionModel;
	matchingOrdersLoading: boolean = true;
	loadedPrescriptionLoading: boolean = true;
	selectedOrderLoading: boolean = true;

	displayedColumns: string[] = ['orderNumber', 'orderDate', 'shopOrderNumber', 'customerName', 'status'];
	dataSource: MatTableDataSource<CustomerOrderListModel> = new MatTableDataSource<CustomerOrderListModel>();
	orderQuery: CustomerOrderQueryModel = new CustomerOrderQueryModel();
	paging: PagingListModel;
	type: string;
	loading: boolean = false;
	filterTimeout: any;

	prescriptionId: number;
	canceled: boolean = false;
	orderStatusAll: EnumItem[];

	manualAssignmentActive: boolean = true;
	showCanceledOrders: boolean = false;

	filterForm: FormGroup = new FormGroup<any>({
		filter: new FormControl(''),
	})

	constructor(
		private prescriptionService: PrescriptionService,
		private infoMessageService: InfomessageService,
		private dialog: MatDialog,
		private customerOrderService: CustomerOrderService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private constantsService: ConstantsService
	) {
	}

	ngOnInit(): void {
		this.initStatusList();
		this.loadAllStatus();
		this.orderQuery.page = 1;
		this.orderQuery.per_page = 20;
		this.orderQuery.filter = '';
		this.orderQuery.type = 5;
		this.orderQuery.typeIsNot = true;
		this.orderQuery.prescription = '0';
		this.activatedRoute.params.subscribe((params: Params) => {
			if (params.id) {
				this.prescriptionId = params.id;
			}
		});
		if (this.prescriptionId) {
			this.startWork(this.prescriptionId);
		} else {
			this.startWork();
		}

		this.loadPaginatedAndFilteredOrderList(true);
	}

	ngOnDestroy(): void {
	}

	switchCanceledOrders(): void {
		if (this.showCanceledOrders) {
			this.orderQuery.typeIsNot = false;
			this.orderQuery.type = 6;
			this.loadPaginatedAndFilteredOrderList();
		} else {
			this.orderQuery.typeIsNot = true;
			this.orderQuery.type = 5
			this.loadPaginatedAndFilteredOrderList();
		}
	}

	handleOrder(row: any): void {
		this.loadOrderDetails(row.orderNumber.toString());
	}

	startWork(id?: number): void {
		if (id) {
			this.getUnassignedPrescriptionForWorkWithId(id)
		} else {
			this.getUnassignedPrescriptionForWork();
		}
	}

	cancelWork(leave: boolean = true): void {
		this.cancelPrescriptionManager(leave);
	}

	openPrescriptionImageModal(previewFile: string): void {
		const dialogRef = this.dialog.open(PrescriptionDetailModalComponent, {
			closeOnNavigation: false,
			disableClose: true,
			data: {
				previewFile
			}
		})
	}

	switchOrderDetails(): void {
		this.loadOrderDetails(this.selectedOrder.orderNumber.toString());
	}

	openOrderDetails(orderNumber: string): void {
		const url = this.router.serializeUrl(
			this.router.createUrlTree(['customer-order/details', orderNumber])
		);
		window.open(url, '_blank');
	}

	skipSelectedPrescription(): void {
		const skipModel = {
			skipTime: 86400,
			id: this.loadedPrescription.id
		}
		this.skipPrescription(skipModel);
	}

	assignSelectedPrescriptionToOrder(leave: boolean = false): void {
		const assignPrescriptionModel: AssignPrescriptionModel = {
			prescriptionId: this.loadedPrescription.id,
			orderNumber: this.selectedOrderDetails.orderNumber.toString()
		}
		if (leave) {
			this.canceled = true;
			this.assignPrescriptionToOrderWithoutLink(assignPrescriptionModel);
		} else {
			this.assignPrescriptionToOrder(assignPrescriptionModel);
		}
	}

	applyFilter(): void {
		if (this.filterForm.get('filter').value === this.orderQuery.filter) {
			return;
		}
		clearTimeout(this.filterTimeout);
		this.filterTimeout = setTimeout(() => {
			if (this.filterForm.get('filter').value) {
				this.orderQuery.filter = this.filterForm.get('filter').value;
				this.loadPaginatedAndFilteredOrderList();
			} else {
				this.orderQuery.filter = '';
				this.loadPaginatedAndFilteredOrderList();
			}
		}, 500)
	}

	changeEvent(event: any): void {
		if (event.pageIndex === this.paging.totalPages - 1) {
			this.orderQuery.page = this.paging.totalPages;
			this.orderQuery.per_page = event.pageSize;
			this.loadPaginatedAndFilteredOrderList();
			return;
		}
		if (event.pageIndex === 0) {
			this.orderQuery.page = 1;
			this.orderQuery.per_page = event.pageSize;
			this.loadPaginatedAndFilteredOrderList();
			return;
		}
		if (event.previousPageIndex > event.pageIndex) {
			this.orderQuery.page = this.paging.pageNumber - 1;
			this.orderQuery.per_page = event.pageSize;
			this.loadPaginatedAndFilteredOrderList();
		}
		if (event.previousPageIndex < event.pageIndex) {
			this.orderQuery.page = this.paging.pageNumber + 1;
			this.orderQuery.per_page = event.pageSize;
			this.loadPaginatedAndFilteredOrderList();
		}
		if (event.pageSize !== this.paging.pageSize) {
			this.orderQuery.page = event.pageIndex + 1;
			this.orderQuery.per_page = event.pageSize;
			this.loadPaginatedAndFilteredOrderList();
		}
	}

	activateManualAssignment(): void {
		this.manualAssignmentActive = !this.manualAssignmentActive
	}

	deletePrescriptionById(id: number, loadNext: boolean = true): void {
		this.deletePrescription(id, loadNext);
	}

	private initStatusList(): void {
		this.statusList = [
			{id: 0, name: 'Überprüfung'},
			{id: 1, name: 'Zuordnung'},
			{id: 2, name: 'Zusammenfassung'},
		];
	}

	private getUnassignedPrescriptionForWork(): void {
		this.prescriptionService.getUnassignedPrescriptionForWork()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (response: Response<PrescriptionModel>) => {
					this.loadedPrescription = response.data;
					this.loadedPrescriptionLoading = false;
					this.getMatchingOrders(response.data.id)
					this.orderQuery.filter = '';
					this.loadPaginatedAndFilteredOrderList()
				},
				error: (error: HttpErrorResponse) => {
					if (error.status === 423) {
						this.cancelPrescriptionManager();
					}
					if (error.error.message.includes('already assigned')) {
						this.infoMessageService.createMessage('Ein anderer Nutzer bearbeitet das Rezept bereits', 'Info', 2);
					} else {
						this.canceled = true;
						this.router.navigate(['/prescription/manager']);
						this.infoMessageService.createMessage('Es gibt momentan keine Rezepte zum zuordnen', 'Info', 2);
					}
				}
			});
	}

	private getUnassignedPrescriptionForWorkWithId(id: number): void {
		this.prescriptionService.getUnassignedPrescriptionForWorkWithId(id)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (response: Response<PrescriptionModel>) => {
					this.loadedPrescription = response.data;
					this.loadedPrescriptionLoading = false;
					this.getMatchingOrders(response.data.id)
					this.orderQuery.filter = '';
					this.loadPaginatedAndFilteredOrderList()
				},
				error: (error: HttpErrorResponse) => {
					if (error.status === 423) {
						this.cancelPrescriptionManager();
					}
					if (error.error.message.includes('already assigned')) {
						this.infoMessageService.createMessage('Ein anderer Nutzer bearbeitet das Rezept bereits', 'Info', 2);
					} else {
						this.canceled = true;
						this.router.navigate(['/prescription/manager']);
						this.infoMessageService.createMessage('Es gibt momentan keine Rezepte zum zuordnen', 'Info', 2);
					}
				}
			})
	}

	private cancelPrescriptionManager(leave: boolean = true): void {
		this.prescriptionService.cancelUnassignedPrescriptionWork()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					if (!leave) {
						return;
					}
					this.canceled = true;
					this.router.navigate(['/prescription/manager']);
				},
				error: (error: HttpErrorResponse) => {
					this.infoMessageService.addServerMessage(error.error.message);
				}
			})
	}

	private getMatchingOrders(id: number): void {
		this.prescriptionService.getPrescriptionMatchingOrders(id)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (data: ResponseList<MatchingOrderModel>) => {
					console.log('data', data);
					this.matchingOrders = data.data;
					if (this.matchingOrders.length > 0) {
						this.selectedOrder = this.matchingOrders.reduce(
							(prev: MatchingOrderModel, current: MatchingOrderModel) => (prev.matchScore > current.matchScore) ? prev : current);
						this.loadOrderDetails(this.selectedOrder.orderNumber.toString(), true);
					}
					this.matchingOrdersLoading = false;
					this.selectedOrderLoading = false;
				},
				error: (error: HttpErrorResponse) => {
					this.infoMessageService.addServerMessage(error.error.message);
				}
			})
	}

	private loadOrderDetails(orderNumber: string, fromMatching: boolean = false): void {
		this.customerOrderService.getOrderDetails(orderNumber)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (data: any) => {
					this.selectedOrderDetails = data;
					this.selectedOrder.orderNumber = +this.selectedOrderDetails.orderNumber;
					if (fromMatching) {
						this.filterForm.get('filter').patchValue(this.selectedOrderDetails.orderNumber);
					}
					this.applyFilter();
				},
				error: (error: HttpErrorResponse) => {
					this.infoMessageService.addServerMessage(error.error.message);
				}
			})
	}

	private skipPrescription(skipModel: SkipPrescriptionWorkModel): void {
		this.prescriptionService.skipUnassignedPrescriptionWork(skipModel)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					this.selectedOrder = {orderNumber: 0, matchScore: 0};
					this.selectedOrderDetails = null;
					this.filterForm.get('filter').patchValue('');
					this.getUnassignedPrescriptionForWork();
				},
				error: (error: HttpErrorResponse) => {
					this.infoMessageService.addServerMessage(error.error.message);
				}
			})
	}

	private assignPrescriptionToOrderWithoutLink(assignPrescriptionModel: AssignPrescriptionModel): void {
		this.prescriptionService.assignPrescriptionToOrder(assignPrescriptionModel)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					this.selectedOrder = {orderNumber: 0, matchScore: 0};
					;
					this.selectedOrderDetails = null;
					this.router.navigate(['/prescription/manager']);
				},
				error: (error: HttpErrorResponse) => {
					this.infoMessageService.addServerMessage(error.error.message);
				}
			})
	}

	private assignPrescriptionToOrder(assignPrescriptionModel: AssignPrescriptionModel): void {
		this.prescriptionService.assignPrescriptionToOrder(assignPrescriptionModel)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					this.selectedOrder = {orderNumber: 0, matchScore: 0};
					;
					;
					this.selectedOrderDetails = null;
					this.getUnassignedPrescriptionForWork();
				},
				error: (error: HttpErrorResponse) => {
					this.infoMessageService.addServerMessage(error.error.message);
				}
			})
	}

	private loadPaginatedAndFilteredOrderList(init: boolean = false): void {
		this.loading = true;
		this.customerOrderService.getPaginatedAndFilteredOrderList(this.orderQuery)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (data: Response<CustomerOrderPagingModel>) => {
					if (data) {
						if (init) {
							this.dataSource.paginator = this.paginator;
						}
						this.paging = data.data.paging
						this.dataSource.data = [...data.data.orders];
						this.loading = false;
					}
				},
				error: (error: Error) => {
					this.infoMessageService.addServerMessage(error);
				}
			})
	}

	private loadAllStatus(): void {
		this.constantsService.getAllCustomerOrderStatus()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (data: EnumItem[]) => {
					this.orderStatusAll = data;
					this.loading = false;
				},
				error: (error: Error) => {
					this.infoMessageService.addServerMessage(error);
				}
			})
	}

	private deletePrescription(id: number, loadNext: boolean = true): void {
		this.prescriptionService.deletePrescriptionById(id)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: () => {
					if (loadNext) {
						this.getUnassignedPrescriptionForWork();
					} else {
						this.canceled = true;
						this.router.navigate(['/prescription/manager']);
					}
				},
				error: (error: HttpErrorResponse) => {
					this.infoMessageService.createMessage(error.error.message, 'Error', 2);
				}
			})
	}

	// this function is used from unassignedPrescriptionGuard don't delete
	canDeactivate(): CanDeactivateType {
		if (this.canceled) {
			return true;
		}
		const dialogRef = this.dialog.open(LeavePrescriptionManagerModal, {
			disableClose: true,
			closeOnNavigation: false,
		})
		return dialogRef.afterClosed().pipe(
			map((result: boolean) => {
				if (result) {
					this.cancelWork(false);
					return true;
				} else {
					return false;
				}
			})
		);
	}
}
