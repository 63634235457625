import { EnumItem } from '../../models';

export class EnumUtils {

	static getNameForEnumId(id: number, enumList: EnumItem[]): string {
		let result = '-';

		const enumVal = this.getObjectForEnumId(id, enumList);

		if (enumVal) {
			result = enumVal.name;
		}

		return result;

	}

	static getObjectForEnumId(id: number | boolean, enumList: EnumItem[]): EnumItem {
		if (!enumList) {
			return;
		}

		return enumList.find(
			(val: EnumItem) => {
				return val.id === id;
			}
		);
	}
}
